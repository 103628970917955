/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WalletInUse {
  message: string;
  confirmation_token?: string;
}

export interface EmailChangeItem {
  /** @format uuid */
  id: string;
  /** @format uuid */
  user_id?: string;
  /** @format email */
  address: string;
}

export interface EmailChangeResponse {
  registration: EmailChangeItem;
  change_request: EmailChangeItem;
}

export interface EmailCreateResponse {
  email: EmailChangeItem;
}

export interface EmailChangeConflictResponse {
  registration: EmailChangeItem;
}

export interface EmailChangeConfirmResponse {
  obsolete: EmailChangeItem;
  registration: EmailChangeItem;
}

/** Url links for available document types */
export type DocumentsUploadLinks = CommonFiles & PersonFiles & InstitutionFiles;

export interface LivenessResult {
  success: boolean;
  scan_result_blob?: string;
}

export type VerifiableWalletType = "aptos" | "gem" | "eth_personal_sign" | "near" | "sui" | "xumm";

export type VerifiableWalletCurrency = "eth" | "near" | "apt" | "xrp" | "sui" | "kar";

export type WalletCurrency = "ada" | "apt" | "algo" | "btc" | "eth" | "kar" | "near" | "sol" | "substrate" | "sui" | "xrp";

export interface WalletOwnershipProofCreateRequest {
  type: VerifiableWalletType;
  currency: VerifiableWalletCurrency;
}

export interface WalletOwnershipProofCreateResponse {
  /** @format uuid */
  id: string;
  currency: VerifiableWalletCurrency;
  extra: {
    url?: string;
    qr_code?: string;
  };
  status: "pending" | "success" | "failure";
  proof: string;
  signature?: string;
  /** @format uuid */
  user_id?: string;
  type: VerifiableWalletType;
  /** @format uuid */
  nonce: string;
  message: string;
}

export interface WalletOwnershipProofConfirmRequest {
  address: string;
  nonce: string;
  signature: string;
  public_key?: string;
  type: VerifiableWalletType;
  block_id?: string;
  success?: boolean;
  callback_url?: string;
}

export interface WalletOwnershipProofDetailResponse {
  /** @format uuid */
  id: string;
  currency: VerifiableWalletCurrency;
  extra: {
    url?: string;
    qr_code?: string;
  };
  status: "pending" | "success" | "failure";
  proof: string;
  signature?: string;
  /** @format uuid */
  user_id?: string;
  /** @format date-time */
  created_at: string;
  type: VerifiableWalletType;
  /** @format uuid */
  nonce: string;
  message: string;
  wallet?: Wallet;
}

export interface UserV1 {
  /**
   * @format uuid
   * @example "0430f00b-d9ee-44d9-893c-8ed7c47a1276"
   */
  uid?: string;
  secret_keyphrase?: string;
  primary_role?: PrimaryRole;
  /** @format enum */
  preferred_language?: "en" | "zh" | "ru";
  migratable?: boolean;
  liveness_allowed?: boolean;
  emails?: Email[];
  phones?: Phone[];
  person?: Person;
  institution?: Institution;
  verification_cases?: VerificationCaseV1[];
  verifications?: Verification[];
  verification_requests?: VerificationRequest[];
  wallets?: Wallet[];
  /** @format date */
  wants_to_be_deleted?: string;
}

export interface StatsTotalVerificationV2 {
  pending: number;
  contacted: number;
  approved: number;
  rejected: number;
  expired: number;
}

export type StatsUserVerificationV2 = {
  level?: string;
  status: string;
  /** @format uuid */
  user_id: string;
}[];

export type StatsCountryVerificationV2 = Record<
  string,
  {
    pending?: number;
    contacted?: number;
    approved?: number;
    rejected?: number;
    expired?: number;
  }
>;

export type WalletsRes = Wallet[];

export interface WalletsUpdateErr {
  /** Missing wallets */
  wallets?: {
    /** @format uuid */
    client_id: string;
    client_name: string;
    currency: string;
    verified?: boolean;
  }[];
}

export interface WalletUpdate {
  /** Only for updating wallet */
  id?: string;
  address: string;
  currency: WalletCurrency;
  /** Protected for get */
  client_ids: string[];
}

export interface UserV2 {
  /**
   * @format uuid
   * @example "0430f00b-d9ee-44d9-893c-8ed7c47a1276"
   */
  uid: string;
  secret_keyphrase: string;
  primary_role?: PrimaryRole;
  idos_human_id?: string;
  /** @format enum */
  preferred_language: "en" | "zh" | "ru";
  migratable?: MigratableUser;
  liveness_allowed: boolean;
  /** @deprecated */
  person?: Person;
  /** @deprecated */
  institution?: Institution;
  emails?: Email[];
  phones?: Phone[];
  verification_cases: VerificationCaseV2[];
  wallets: Wallet[];
  client_policy_acceptances: ClientPolicyAcceptance[];
  policy_acceptances: PolicyAcceptance[];
  /** @format date */
  wants_to_be_deleted?: string;
}

export interface VerificationCaseToken {
  token: string;
}

export interface ClientPolicyAcceptance {
  /** @format uuid */
  id: string;
  /** @format uuid */
  catfish_policy_id: string;
  /** @format datetime */
  accepted_at: string;
  /** @format datetime */
  revoked_at?: string;
}

export interface PolicyAcceptance {
  /** @format uuid */
  id: string;
  policy: string;
  version: string;
  /** @format datetime */
  accepted_at: string;
  /** @format datetime */
  revoked_at?: string;
}

export type OCRVerificationStatus = "created" | "error" | "processing" | "done";

export type OCRVerificationSource = "regula" | "keesing";

export type FailedCheck =
  | "page_indexes_match_sides"
  | "fdsid_list_consistent"
  | "uploaded_files_are_not_same_side"
  | "number_of_images_matches_document_type"
  | "mrz_data_verification"
  | "mrz_visual_comparison";

export interface ParsedDocumentType {
  country: string;
  /** https://docs.regulaforensics.com/develop/doc-reader-sdk/web-service/development/enums/document-type/ */
  type: number;
}

export interface OCRVerificationParsedReport {
  date_of_birth?: string;
  date_of_expiry?: string;
  date_of_issue?: string;
  document_country?: string;
  document_number?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  mrz_data_verification: boolean;
  mrz_exists: boolean;
  nationality?: string;
  passed: boolean;
}

export interface OCRVerification {
  /** @format uuid */
  id: string;
  status: OCRVerificationStatus;
  source: OCRVerificationSource;
  failed_checks: FailedCheck[];
  stale: boolean;
  parsed_report?: OCRVerificationParsedReport;
  parsed_report_mrz_data?: OCRVerificationParsedReport;
  parsed_document_types: ParsedDocumentType[];
}

export type IdentificationDocumentType = "drivers_license" | "national_id" | "passport";

export interface CommonFiles {
  /** @format url */
  accredited_investor_proof_file?: string;
  /** @format url */
  identification_document_back_file?: string;
  /** @format url */
  identification_document_front_file?: string;
  /** @format url */
  identification_document_selfie_file?: string;
  /** @format url */
  liveness_audit_best_file?: string;
  /** @format url */
  liveness_audit_least_similar_file?: string;
  /** @format url */
  liveness_audit_open_eyes_file?: string;
  /** @format url */
  liveness_audit_quality1_file?: string;
  /** @format url */
  liveness_audit_quality2_file?: string;
  /** @format url */
  liveness_audit_quality3_file?: string;
  /** @format url */
  liveness_facemap_file?: string;
  /** @format url */
  residential_address_proof_file?: string;
  /** @format url */
  social_security_card_file?: string;
}

export type LegalEntityCommonProperties = CommonFiles & {
  accredited_investor?: boolean;
  /** @format date */
  date_of_birth?: string;
  full_name?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  identification_document_country?: string;
  /** @format date */
  identification_document_date_of_expiry?: string;
  /** @format date */
  identification_document_date_of_issue?: string;
  identification_document_number?: string;
  identification_document_type?: IdentificationDocumentType;
  non_pep?: string;
  non_sanction?: string;
  place_of_birth?: string;
  residential_address_country?: string;
  residential_address_line1?: string;
  residential_address_line2?: string;
  residential_address_city?: string;
  residential_address_zip?: string;
  residential_address_proof_category?: string;
  residential_address_proof_date_of_issue?: string;
  sow_estimated_net_worth_currency?: string;
  sow_estimated_net_worth_value?: string;
  telegram_username?: string;
  twitter_username?: string;
  video?: string;
  liveness?: boolean;
  wallet_currency?: "ETH" | "BTC";
  /** @format datetime */
  accredited_investor_proof_file_created_at?: string;
  /** @format datetime */
  accredited_investor_proof_file_updated_at?: string;
  /** @format datetime */
  identification_document_back_file_created_at?: string;
  /** @format datetime */
  identification_document_back_file_updated_at?: string;
  /** @format datetime */
  identification_document_front_file_created_at?: string;
  /** @format datetime */
  identification_document_front_file_updated_at?: string;
  /** @format datetime */
  identification_document_selfie_file_created_at?: string;
  /** @format datetime */
  identification_document_selfie_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_best_file_created_at?: string;
  /** @format datetime */
  liveness_audit_best_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_least_similar_file_created_at?: string;
  /** @format datetime */
  liveness_audit_least_similar_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_open_eyes_file_created_at?: string;
  /** @format datetime */
  liveness_audit_open_eyes_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_quality1_file_created_at?: string;
  /** @format datetime */
  liveness_audit_quality1_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_quality2_file_created_at?: string;
  /** @format datetime */
  liveness_audit_quality2_file_updated_at?: string;
  /** @format datetime */
  liveness_audit_quality3_file_created_at?: string;
  /** @format datetime */
  liveness_audit_quality3_file_updated_at?: string;
  /** @format datetime */
  liveness_facemap_file_created_at?: string;
  /** @format datetime */
  liveness_facemap_file_updated_at?: string;
  /** @format datetime */
  residential_address_proof_file_created_at?: string;
  /** @format datetime */
  residential_address_proof_file_updated_at?: string;
  /** @format datetime */
  social_security_card_file_created_at?: string;
  /** @format datetime */
  social_security_card_file_updated_at?: string;
  near_social_name?: string;
  phone_number?: string;
};

export interface PersonFiles {
  /** @format url */
  sow_crypto_bank_statement_file?: string;
  /** @format url */
  sow_crypto_source_of_initial_investment_file?: string;
  /** @format url */
  sow_crypto_transactions_last_3_months_file?: string;
  /** @format url */
  sow_dividends_bank_statement_file?: string;
  /** @format url */
  sow_dividends_entitlement_file?: string;
  /** @format url */
  sow_divorce_settlement_bank_statement_file?: string;
  /** @format url */
  sow_divorce_settlement_proof_file?: string;
  /** @format url */
  sow_freelance_bank_statement_file?: string;
  /** @format url */
  sow_freelance_last_year_tax_returns_file?: string;
  /** @format url */
  sow_freelance_service_contracts_file?: string;
  /** @format url */
  sow_gifts_bank_statement_file?: string;
  /** @format url */
  sow_inheritance_bank_statement_file?: string;
  /** @format url */
  sow_inheritance_proof_file?: string;
  /** @format url */
  sow_lease_revenue_agreement_file?: string;
  /** @format url */
  sow_lease_revenue_bank_statement_file?: string;
  /** @format url */
  sow_life_policy_bank_statement_file?: string;
  /** @format url */
  sow_life_policy_closing_statement_file?: string;
  /** @format url */
  sow_pension_bank_statement_file?: string;
  /** @format url */
  sow_pension_statement_file?: string;
  /** @format url */
  sow_salary_bank_statement_file?: string;
  /** @format url */
  sow_salary_last_year_tax_returns_file?: string;
  /** @format url */
  sow_salary_pay_slips_last_3_months_file?: string;
  /** @format url */
  sow_sale_of_company_bank_statement_file?: string;
  /** @format url */
  sow_sale_of_company_contract_file?: string;
  /** @format url */
  sow_sale_of_property_bank_statement_file?: string;
  /** @format url */
  sow_sale_of_property_contract_file?: string;
  /** @format url */
  sow_sale_of_shares_bank_statement_file?: string;
  /** @format url */
  sow_sale_of_shares_proof_file?: string;
}

export type Person = LegalEntityCommonProperties &
  PersonFiles & {
    ocr_validation_completed?: boolean;
    identification_document_ocr?: OCRVerification;
    residential_address?: string;
    social_security_number?: string;
    sow_gifts_name_of_donator?: string;
    sow_gifts_reason_for_donation?: string;
    sow_gifts_relationship_with_donator?: string;
    sow_type?: SoWType;
    /** @format datetime */
    sow_crypto_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_crypto_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_crypto_source_of_initial_investment_file_created_at?: string;
    /** @format datetime */
    sow_crypto_source_of_initial_investment_file_updated_at?: string;
    /** @format datetime */
    sow_crypto_transactions_last_3_months_file_created_at?: string;
    /** @format datetime */
    sow_crypto_transactions_last_3_months_file_updated_at?: string;
    /** @format datetime */
    sow_dividends_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_dividends_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_dividends_entitlement_file_created_at?: string;
    /** @format datetime */
    sow_dividends_entitlement_file_updated_at?: string;
    /** @format datetime */
    sow_divorce_settlement_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_divorce_settlement_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_divorce_settlement_proof_file_created_at?: string;
    /** @format datetime */
    sow_divorce_settlement_proof_file_updated_at?: string;
    /** @format datetime */
    sow_freelance_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_freelance_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_freelance_last_year_tax_returns_file_created_at?: string;
    /** @format datetime */
    sow_freelance_last_year_tax_returns_file_updated_at?: string;
    /** @format datetime */
    sow_freelance_service_contracts_file_created_at?: string;
    /** @format datetime */
    sow_freelance_service_contracts_file_updated_at?: string;
    /** @format datetime */
    sow_gifts_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_gifts_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_inheritance_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_inheritance_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_inheritance_proof_file_created_at?: string;
    /** @format datetime */
    sow_inheritance_proof_file_updated_at?: string;
    /** @format datetime */
    sow_lease_revenue_agreement_file_created_at?: string;
    /** @format datetime */
    sow_lease_revenue_agreement_file_updated_at?: string;
    /** @format datetime */
    sow_lease_revenue_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_lease_revenue_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_life_policy_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_life_policy_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_life_policy_closing_statement_file_created_at?: string;
    /** @format datetime */
    sow_life_policy_closing_statement_file_updated_at?: string;
    /** @format datetime */
    sow_pension_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_pension_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_pension_statement_file_created_at?: string;
    /** @format datetime */
    sow_pension_statement_file_updated_at?: string;
    /** @format datetime */
    sow_salary_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_salary_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_salary_last_year_tax_returns_file_created_at?: string;
    /** @format datetime */
    sow_salary_last_year_tax_returns_file_updated_at?: string;
    /** @format datetime */
    sow_salary_pay_slips_last_3_months_file_created_at?: string;
    /** @format datetime */
    sow_salary_pay_slips_last_3_months_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_company_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_company_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_company_contract_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_company_contract_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_property_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_property_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_property_contract_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_property_contract_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_shares_bank_statement_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_shares_bank_statement_file_updated_at?: string;
    /** @format datetime */
    sow_sale_of_shares_proof_file_created_at?: string;
    /** @format datetime */
    sow_sale_of_shares_proof_file_updated_at?: string;
  };

export interface InstitutionFiles {
  /** @format url */
  articles_of_association_file?: string;
  /** @format url */
  certificate_of_corporate_status_file?: string;
  /** @format url */
  certificate_of_good_standing_file?: string;
  /** @format url */
  certificate_of_incorporation_file?: string;
  /** @format url */
  commercial_register_entry_file?: string;
  /** @format url */
  owner_identity_proof_file?: string;
  /** @format url */
  power_of_attorney_file?: string;
  /** @format url */
  secretary_certificate_file?: string;
  /** @format url */
  shareholders_list_file?: string;
  /** @format url */
  sow_balance_sheet_file?: string;
  /** @format url */
  sow_cash_flow_statement_file?: string;
  /** @format url */
  sow_income_statement_file?: string;
  /** @format url */
  transparency_register_entry_file?: string;
}

export type Institution = LegalEntityCommonProperties &
  InstitutionFiles & {
    company_seat?: string;
    beneficial_owner?: string;
    commercial_register?: string;
    company_name?: string;
    jurisdiction?: string;
    legal_form?: string;
    managing_directors?: string;
    sow_unaudited_accountant_name?: string;
    sow_unaudited_accountant_professional_body?: string;
    unique_identification_number?: string;
    /** @format datetime */
    articles_of_association_file_created_at?: string;
    /** @format datetime */
    articles_of_association_file_updated_at?: string;
    /** @format datetime */
    certificate_of_corporate_status_file_created_at?: string;
    /** @format datetime */
    certificate_of_corporate_status_file_updated_at?: string;
    /** @format datetime */
    certificate_of_good_standing_file_created_at?: string;
    /** @format datetime */
    certificate_of_good_standing_file_updated_at?: string;
    /** @format datetime */
    certificate_of_incorporation_file_created_at?: string;
    /** @format datetime */
    certificate_of_incorporation_file_updated_at?: string;
    /** @format datetime */
    commercial_register_entry_file_created_at?: string;
    /** @format datetime */
    commercial_register_entry_file_updated_at?: string;
    /** @format datetime */
    owner_identity_proof_file_created_at?: string;
    /** @format datetime */
    owner_identity_proof_file_updated_at?: string;
    /** @format datetime */
    power_of_attorney_file_created_at?: string;
    /** @format datetime */
    power_of_attorney_file_updated_at?: string;
    /** @format datetime */
    secretary_certificate_file_created_at?: string;
    /** @format datetime */
    secretary_certificate_file_updated_at?: string;
    /** @format datetime */
    shareholders_list_file_created_at?: string;
    /** @format datetime */
    shareholders_list_file_updated_at?: string;
    /** @format datetime */
    sow_balance_sheet_file_created_at?: string;
    /** @format datetime */
    sow_balance_sheet_file_updated_at?: string;
    /** @format datetime */
    sow_cash_flow_statement_file_created_at?: string;
    /** @format datetime */
    sow_cash_flow_statement_file_updated_at?: string;
    /** @format datetime */
    sow_income_statement_file_created_at?: string;
    /** @format datetime */
    sow_income_statement_file_updated_at?: string;
    /** @format datetime */
    transparency_register_entry_file_created_at?: string;
    /** @format datetime */
    transparency_register_entry_file_updated_at?: string;
  };

export type EmailSourceType = "registration" | "journey" | "obsolete";

export interface MigratableUser {
  source: string;
}

export interface Email {
  /** @example "developers@fractal.id" */
  address: string;
  /** @format datetime */
  confirmed_at?: string;
  source_type: EmailSourceType;
}

export interface Phone {
  /** @example "+43099283838292" */
  number: string;
}

export interface VerificationCaseListV1 {
  verification_cases: VerificationCaseV1[];
  total: number;
}

export interface Export {
  /** @format uuid */
  id: string;
  status: "pending" | "done" | "failed";
  format: "csv" | "json" | "csv+zip" | "xlsx";
  notified: boolean;
  duration?: number;
  /** @format url */
  file_url?: string;
  /** @format datetime */
  finished_at?: string;
  /** @format datetime */
  updated_at: string;
  /** @format datetime */
  created_at: string;
}

export interface CredentialsListV1 {
  credentials: CredentialV1[];
  total: number;
}

export interface CredentialV1 {
  /** @format uuid */
  id: string;
  address: string;
  blocked_citizenship_countries: string[];
  blocked_residency_countries: string[];
  level: string;
  /** @format datetime */
  created_at: string;
  share_pii: boolean;
  verification_case?: VerificationCaseV1;
}

export interface VerificationCaseV1 {
  uid: string;
  client_id: string;
  credential: "pending" | "approved" | "rejected";
  /** @format datetime */
  journey_completed_at?: string;
  /** @format datetime */
  approved_at?: string;
  /** @format email */
  email?: string;
  wallets?: Wallet[];
  emails?: Email[];
  phone?: string;
  full_name?: string;
  residential_address_country?: string;
  primary_role: PrimaryRole;
  /** Person or Institution subset of approved data */
  details: Person | Institution;
  /** @format uuid */
  id: string;
  /** Fill missing data button in UI */
  journey_completed: boolean;
  level: string;
  /** Mark for contact message */
  message?: string;
  status: "pending" | "contacted" | "done";
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
}

export type SumSubStatus = "not_initialized" | "pending" | "approved" | "rejected";

export type VerificationCaseStatus = "pending" | "contacted" | "expired" | "approved" | "rejected";

export type PrimaryRole = "person" | "institution";

export type SoWType =
  | "gifts"
  | "sale_of_property"
  | "sale_of_shares"
  | "sale_of_company"
  | "salary"
  | "inheritance"
  | "lease_revenue"
  | "life_policy"
  | "freelance"
  | "divorce_settlement"
  | "dividends"
  | "pension"
  | "crypto";

export interface VerificationCaseV2 {
  client_id: string;
  /** Person or Institution subset of approved data */
  details?: Person | Institution;
  /** @format uuid */
  id: string;
  /** Fill missing data button in UI */
  journey_completed: boolean;
  /**
   * VCs' tied user version residential proof of date of expiry
   * @format date
   */
  residential_address_proof_date_of_expiry?: string;
  /**
   * Latest user detail residential proof of date of expiry
   * @format date
   */
  latest_residential_address_proof_date_of_expiry?: string;
  level: string;
  /** Mark for contact message */
  message?: string;
  sum_sub_status: SumSubStatus;
  status: VerificationCaseStatus;
  /** Only for plus+ cases */
  expiration_intervals?: ExpirationIntervals;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
}

export interface ExpirationIntervals {
  delivery_receipt: number;
  official_documents: number;
  official_correspondence: number;
  utility_bill: number;
  invalid: number;
}

export interface VerificationRequest {
  /** @format uuid */
  client_id?: string;
  /** @format datetime */
  created_at?: string;
  level?: string;
  status?: "pending" | "approved";
}

export interface Verification {
  details?: object;
  level?: string;
  status?: "pending" | "approved";
  report?: object;
}

export type Wallet = WalletUpdate & {
  /** Available just for KAR / ETH / NEAR / SUI / APTOS wallets */
  verified: boolean;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
};

export interface IdosData {
  /** @format uuid */
  idos_human_id?: string;
  wallets: IdosWallet[];
  credentials: IdosCredential[];
  available_credentials: IdosAvailableCredential[];
}

export interface IdosWallet {
  currency: WalletCurrency;
  /** @format datetime */
  address: string;
}

export interface IdosCredential {
  level: IdosCredentialLevel;
  credential_type: IdosCredentialType;
  /** @format datetime */
  created_at: string;
}

export type IdosCredentialLevel = "human" | "basic" | "plus";

export type IdosCredentialType = "human" | "kyc";

export interface IdosAvailableCredential {
  type: IdosCredentialType;
  level: IdosCredentialLevel;
}

export interface IdosEncryptionCredentials {
  credentials: {
    /** @format byte */
    enc_public_key: string;
  };
}

export interface IdosAddUserResponse {
  human: {
    status: "added" | "error";
    /** @format uuid */
    human_id?: string;
  };
}

export interface IdosResetPasswordResponse {
  credentials: {
    status: "added" | "error";
    type?: IdosCredentialType;
    /** @format uuid */
    idos_credential_id?: string;
  }[];
}

export interface UpdateUser {
  email?: string;
  email_otp?: string;
  legal_entity: object;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://resource.fractal.id" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Fractal Resource API
 * @version 2.0
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://resource.fractal.id
 * @contact <developers@fractal.id>
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  emails = {
    /**
     * No description
     *
     * @tags emails
     * @name CreateEmail
     * @summary Create users email from journey
     * @request POST:/emails
     */
    createEmail: (
      data: {
        /** @format uuid */
        user_id: string;
        /** @format email */
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmailCreateResponse, EmailCreateResponse>({
        path: `/emails`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name CreateEmailConfirm
     * @summary Confirm users creation email
     * @request POST:/emails/create_confirm
     */
    createEmailConfirm: (
      data: {
        /** @format uuid */
        user_id: string;
        /** @format uuid */
        email_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/emails/create_confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name ChangeEmail
     * @summary Change users' email
     * @request POST:/emails/change
     */
    changeEmail: (
      data: {
        /** @format uuid */
        user_id: string;
        /** @format email */
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmailChangeResponse, EmailChangeConflictResponse>({
        path: `/emails/change`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name ChangeEmailConfirm
     * @summary Confirm users email change and make all other emails obsolete.
     * @request POST:/emails/change_confirm
     */
    changeEmailConfirm: (
      data: {
        /** @format uuid */
        registration_id: string;
        /** @format uuid */
        change_request_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmailChangeConfirmResponse, void>({
        path: `/emails/change_confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags events
     * @name CreateEvent
     * @summary Create an event
     * @request POST:/events
     */
    createEvent: (
      data: {
        type: string;
        payload: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/events`,
        method: "POST",
        body: data,
        ...params,
      }),
  };
  liveness = {
    /**
     * No description
     *
     * @tags liveness
     * @name GetLivenessSessionToken
     * @summary Return liveness session token
     * @request GET:/liveness
     */
    getLivenessSessionToken: (params: RequestParams = {}) =>
      this.request<
        {
          session_token: string;
        },
        any
      >({
        path: `/liveness`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags liveness
     * @name CheckLiveness
     * @summary Check liveness result
     * @request POST:/liveness
     */
    checkLiveness: (params: RequestParams = {}) =>
      this.request<LivenessResult, any>({
        path: `/liveness`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  verifications = {
    /**
     * No description
     *
     * @tags verifications
     * @name CreateVerification
     * @summary Create a new verification
     * @request POST:/verifications
     */
    createVerification: (
      data: {
        level: string;
        client_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/verifications`,
        method: "POST",
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags verifications
     * @name RefreshVerificationRequests
     * @summary Refresh verification requests
     * @request PUT:/verifications/refresh
     */
    refreshVerificationRequests: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verifications/refresh`,
        method: "PUT",
        ...params,
      }),
  };
  verificationCases = {
    /**
     * No description
     *
     * @tags verificationCases
     * @name GetVerificationCasesExports
     * @summary Get last 10 verification cases exports
     * @request GET:/verification-cases/exports
     */
    getVerificationCasesExports: (params: RequestParams = {}) =>
      this.request<Export[], any>({
        path: `/verification-cases/exports`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags verificationCases
     * @name CreateVerificationCasesExports
     * @summary Create a new verification case export
     * @request POST:/verification-cases/exports
     */
    createVerificationCasesExports: (
      data: {
        /** @format uuid */
        scoped_user_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Export, any>({
        path: `/verification-cases/exports`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags verificationCases
     * @name GetVerificationCases
     * @summary Get a list of verification cases
     * @request GET:/verification-cases/
     */
    getVerificationCases: (
      query?: {
        /** Filter by type */
        filter?: string;
        /** Filter by query */
        query?: string;
        offset?: number;
        limit?: number;
        order_by_field?: "created_at" | "updated_at";
        order_by_direction?: "asc" | "desc";
      },
      params: RequestParams = {},
    ) =>
      this.request<VerificationCaseListV1, any>({
        path: `/verification-cases/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags verificationCases
     * @name GetVerificationCase
     * @summary Get a verification case
     * @request GET:/verification-cases/{vcId}
     */
    getVerificationCase: (vcId: string, params: RequestParams = {}) =>
      this.request<VerificationCaseV1, any>({
        path: `/verification-cases/${vcId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags verificationCases
     * @name CreateVerificationCase
     * @summary Create a new verification case
     * @request POST:/verification-cases
     */
    createVerificationCase: (
      data: {
        level: string;
        client_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<VerificationCaseV1, any>({
        path: `/verification-cases`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags verificationCases
     * @name CreateVerificationCaseToken
     * @summary Create a new verification case sum-sub token
     * @request POST:/verification-cases/{id}/token
     */
    createVerificationCaseToken: (id: string, params: RequestParams = {}) =>
      this.request<VerificationCaseToken, any>({
        path: `/verification-cases/${id}/token`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  credentials = {
    /**
     * No description
     *
     * @tags credentials
     * @name GetCredentials
     * @summary Get a list of credentials
     * @request GET:/credentials/list
     */
    getCredentials: (
      query?: {
        /** Filter by type */
        filter?: string;
        /** Filter by query */
        query?: string;
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialsListV1, any>({
        path: `/credentials/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags credentials
     * @name GetCredentialDetail
     * @summary Get a credential detail
     * @request GET:/credentials/{credentialId}
     */
    getCredentialDetail: (credentialId: string, params: RequestParams = {}) =>
      this.request<CredentialV1, any>({
        path: `/credentials/${credentialId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags credentials
     * @name GetCredentialsExport
     * @summary Get last 10 credentials exports
     * @request GET:/credentials/exports
     */
    getCredentialsExport: (params: RequestParams = {}) =>
      this.request<Export[], any>({
        path: `/credentials/exports`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags credentials
     * @name CreateCredentialsExports
     * @summary Create a new credential export
     * @request POST:/credentials/exports
     */
    createCredentialsExports: (
      data: {
        /** @format uuid */
        scoped_user_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Export, any>({
        path: `/credentials/exports`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),
  };
  policyAcceptances = {
    /**
     * No description
     *
     * @tags policies
     * @name CreatePolicyAcceptance
     * @summary Create a new policy acceptance
     * @request POST:/policy-acceptances
     */
    createPolicyAcceptance: (
      data: {
        /** @format uuid */
        policy: string;
        version: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PolicyAcceptance, any>({
        path: `/policy-acceptances`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags policies
     * @name DeletePolicyAcceptance
     * @summary Delete a policy acceptance
     * @request DELETE:/policy-acceptances/{id}
     */
    deletePolicyAcceptance: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/policy-acceptances/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  clientPolicyAcceptances = {
    /**
     * No description
     *
     * @tags policies
     * @name CreateClientPolicyAcceptance
     * @summary Create a new client policy acceptance
     * @request POST:/client-policy-acceptances
     */
    createClientPolicyAcceptance: (
      data: {
        /** @format uuid */
        policy: string;
        accepted: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientPolicyAcceptance, any>({
        path: `/client-policy-acceptances`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags policies
     * @name DeleteClientPolicyAcceptance
     * @summary Delete a client policy acceptance
     * @request DELETE:/client-policy-acceptances/{id}
     */
    deleteClientPolicyAcceptance: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-policy-acceptances/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags user
     * @name CreateDocumentsUploadLinks
     * @summary Create a documents upload links
     * @request PUT:/users/documents
     */
    createDocumentsUploadLinks: (
      data: {
        legal_entity: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<DocumentsUploadLinks, any>({
        path: `/users/documents`,
        method: "PUT",
        body: data,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateUserPreferences
     * @summary Update user preferences
     * @request PUT:/users/preferences
     */
    updateUserPreferences: (
      data: {
        user: {
          preferred_language: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/preferences`,
        method: "PUT",
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name SetWantsToBeDeleted
     * @summary Sets the user's intent to be deleted
     * @request POST:/users/wants_to_be_deleted
     */
    setWantsToBeDeleted: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/wants_to_be_deleted`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUser
     * @summary Get user data
     * @request GET:/users/me
     * @deprecated
     */
    getUser: (params: RequestParams = {}) =>
      this.request<UserV1, void>({
        path: `/users/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateUser
     * @summary Update user data
     * @request PUT:/users/me
     */
    updateUser: (data: UpdateUser, params: RequestParams = {}) =>
      this.request<UserV2, any>({
        path: `/users/me`,
        method: "PUT",
        body: data,
        format: "json",
        ...params,
      }),
  };
  roles = {
    /**
     * No description
     *
     * @tags user
     * @name SetRole
     * @summary Set a user's role.
     * @request POST:/roles
     */
    setRole: (
      data: {
        role?: PrimaryRole;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/roles`,
        method: "POST",
        body: data,
        ...params,
      }),
  };
  dataMigration = {
    /**
     * @description Accept data migration.
     *
     * @tags migration
     * @name AcceptDataMigration
     * @summary Accept data migration.
     * @request POST:/data-migration/accept
     */
    acceptDataMigration: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/data-migration/accept`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Reject data migration
     *
     * @tags migration
     * @name RejectDataMigration
     * @summary Reject data migration.
     * @request DELETE:/data-migration/reject
     */
    rejectDataMigration: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/data-migration/reject`,
        method: "DELETE",
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags user
     * @name GetUserV2
     * @summary Get user data
     * @request GET:/v2/users/me
     */
    getUserV2: (params: RequestParams = {}) =>
      this.request<UserV2, void>({
        path: `/v2/users/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags stats
     * @name GetStatsTotalVerifications
     * @summary Get total verification statistics
     * @request GET:/v2/stats/total-verifications
     */
    getStatsTotalVerifications: (params: RequestParams = {}) =>
      this.request<StatsTotalVerificationV2, any>({
        path: `/v2/stats/total-verifications`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags stats
     * @name GetStatsCountryVerifications
     * @summary Get country verification statistics
     * @request GET:/v2/stats/country-verifications
     */
    getStatsCountryVerifications: (params: RequestParams = {}) =>
      this.request<StatsCountryVerificationV2, void>({
        path: `/v2/stats/country-verifications`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags stats
     * @name GetStatsUserVerifications
     * @summary Get user verification statistics
     * @request GET:/v2/stats/user-verifications
     */
    getStatsUserVerifications: (params: RequestParams = {}) =>
      this.request<StatsUserVerificationV2, void>({
        path: `/v2/stats/user-verifications`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wallets = {
    /**
     * No description
     *
     * @tags wallets
     * @name GetWallets
     * @summary Get user wallets
     * @request GET:/wallets
     */
    getWallets: (params: RequestParams = {}) =>
      this.request<WalletsRes, void>({
        path: `/wallets`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallets
     * @name UpdateWallets
     * @summary Update user wallets
     * @request PUT:/wallets
     */
    updateWallets: (
      data: {
        wallets?: WalletUpdate[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UserV2, void | WalletsUpdateErr>({
        path: `/wallets`,
        method: "PUT",
        body: data,
        format: "json",
        ...params,
      }),
  };
  walletOwnership = {
    /**
     * No description
     *
     * @tags walletOwnership
     * @name CreateProof
     * @summary Create wallet ownership proof for sign.
     * @request POST:/wallet-ownership
     */
    createProof: (data: WalletOwnershipProofCreateRequest, params: RequestParams = {}) =>
      this.request<WalletOwnershipProofCreateResponse, any>({
        path: `/wallet-ownership`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags walletOwnership
     * @name ConfirmProof
     * @summary Confirm wallet ownership proof.
     * @request POST:/wallet-ownership/submit
     */
    confirmProof: (data: WalletOwnershipProofConfirmRequest, params: RequestParams = {}) =>
      this.request<Wallet, WalletInUse>({
        path: `/wallet-ownership/submit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags walletOwnership
     * @name ProofDetail
     * @summary Get proof detail.
     * @request GET:/wallet-ownership/{id}
     */
    proofDetail: (id: string, params: RequestParams = {}) =>
      this.request<WalletOwnershipProofDetailResponse, any>({
        path: `/wallet-ownership/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  idos = {
    /**
     * No description
     *
     * @tags idos
     * @name GetIdosData
     * @summary Get idOS user data
     * @request GET:/idos
     */
    getIdosData: (params: RequestParams = {}) =>
      this.request<IdosData, void>({
        path: `/idos`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags idos
     * @name AddUserToIdos
     * @summary Adds a user and its wallets and credentials to idOS
     * @request POST:/idos
     */
    addUserToIdos: (data: IdosEncryptionCredentials, params: RequestParams = {}) =>
      this.request<IdosAddUserResponse, void>({
        path: `/idos`,
        method: "POST",
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags idos
     * @name CreateIdosAccessGrant
     * @summary Set idOS access grant signature
     * @request POST:/idos/access-grant-signature
     */
    createIdosAccessGrant: (
      data: {
        owner: string;
        grantee: string;
        data_id: string;
        locked_until: number;
        message: string;
        signature: string;
        chain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/idos/access-grant-signature`,
        method: "POST",
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags idos
     * @name ResetIdosPassword
     * @summary Resets idOS user password
     * @request POST:/idos/reset-password
     */
    resetIdosPassword: (data: IdosEncryptionCredentials, params: RequestParams = {}) =>
      this.request<IdosResetPasswordResponse, void>({
        path: `/idos/reset-password`,
        method: "POST",
        body: data,
        ...params,
      }),
  };
}
