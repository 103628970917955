import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Trans } from "@lingui/macro";
import { useNavigate } from "react-router-dom";

import { goBackUrl } from "lib/browser";

import { ApplicationFormData, createApplication } from "slices/applications";

import ApplicationForm from "components/forms/Application";

const mapDispatchToProps = {
  createApplication,
};

const connector = connect(undefined, mapDispatchToProps);
type ApplicationNewPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationNew({ createApplication }: ApplicationNewPropsFromRedux) {
  const navigate = useNavigate();

  const onSubmit = (values: ApplicationFormData) => {
    void createApplication(values).then((data) => {
      // @ts-expect-error Ignore not fully typed yet
      navigate(`/apps/${data.payload.uid}`);
    });
  };

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application.create">Create new integration</Trans>
        </h2>
      </div>
      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>

        <ApplicationForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default connector(ApplicationNew);
