import React from "react";
import { stringify } from "qs";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { ExternalLink } from "@trustfractal/seabed";

import { REDIRECT_URI } from "lib/api";
import config from "lib/config";
import { pageRefresh } from "lib/browser";
import { useAppSelector } from "lib/hooks";
import { selectCurrentUser } from "slices/currentUser";

const scope = [
  "email:read",
  "uid:read",
  "user.wallets:read",
  "dashboard.application:read",
  "dashboard.application:write",
  "policy_acceptance:read",
  "policy_acceptance:write",
].join(" ");

const LoginButton = () => {
  const params = {
    client_id: config.OAUTH_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: "code",
    scope,
  };

  const authorizeUrl = `${config.NAUTILUS_BASE_URL}/authorize?${stringify(params)}`;
  const handleClick = () => pageRefresh(authorizeUrl);

  return (
    <button className="btn primary" onClick={handleClick}>
      <Trans id="home.login">Login and start setup</Trans>
    </button>
  );
};

export default function Home() {
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    <div className="home text-center">
      <h1>Fractal ID Admin</h1>

      <p className="text-center">
        <Trans id="home.features">
          Set up your integration with Fractal ID. See statistics about your onboarding funnel. Explore the information collected from your
          users.
        </Trans>
      </p>

      <p className="text-center">
        <Trans id="home.more_info">
          For more information, consult our{" "}
          <ExternalLink href="https://docs.developer.fractal.id/" className="inline-block">
            integration documentation
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink href="https://web.fractal.id/" className="inline-block">
            our website
          </ExternalLink>
          .
        </Trans>
      </p>

      <p className="text-center">
        <strong>
          <Trans id="home.get_started">Start verifying your users today.</Trans>
        </strong>
      </p>

      <div className="home__actions">
        {currentUser ? (
          <Link to="/apps" className="btn primary">
            Go to dashboard
          </Link>
        ) : (
          <LoginButton />
        )}
      </div>
    </div>
  );
}
