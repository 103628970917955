import React from "react";
import { t } from "@lingui/macro";

import DetailsGroup, { DetailsGroupOption } from "components/common/DetailsGroup";
import { VerificationCase } from "lib/verification_case";

export default function Wallets({ verificationCase }: { verificationCase: VerificationCase }) {
  const labelValueList: DetailsGroupOption[] =
    verificationCase.wallets?.map((wallet) => ({
      label: wallet.currency,
      value: wallet.address,
    })) ?? [];

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.wallet.title",
        message: "Wallets",
      })}
      labelValueList={labelValueList}
    />
  );
}
