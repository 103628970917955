import { keyBy, mapValues } from "lodash";

import countries from "assets/countries.json";
import { type IdentificationDocumentType } from "api/megalodon";

const documentTypes = {
  passport: "Passport",
  national_id: "National ID",
  drivers_license: "Driver's License",
};

const keyedCountries = mapValues(keyBy(countries, "value"), "label");

export function countryName(iso?: string): string | undefined {
  if (!iso) {
    return iso;
  } else {
    return keyedCountries[iso] || iso;
  }
}

export function documentType(key: IdentificationDocumentType): string {
  return documentTypes[key] || key;
}
