import { reducer as form } from "redux-form";

import webhooks from "reducers/webhooks";

import language from "slices/language";
import currentUser from "slices/currentUser";
import applicationUsers from "slices/applicationUsers";
import applications from "slices/applications";

import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";

const appReducer = combineReducers({
  applications,
  applicationUsers,
  currentUser,
  form,
  language,
  webhooks,
});

const RESET_STORE = "RESET_STORE";
const resetStore = createAction(RESET_STORE);

/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
const rootReducer = (state, action) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (action.type === RESET_STORE) {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    return appReducer(undefined, action);
  }

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export { resetStore };
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
