import { type VerificationCaseV1, type Person, type Institution } from "api/megalodon";

export interface ReviewReason {
  reason: string;
  category: string;
}

export interface ComplyAdvantageHit {
  source_notes_with_fields: {
    types: string[];
    name: string;
    url: string;
    fields?: {
      field: string;
      values: string[];
    }[];
  }[];

  alternative_names: {
    name: string;
  }[];

  media: {
    url: string;
    date?: string;
    title: string;
    snippet: string;
  }[];

  associates: {
    name: string;
    association: string;
  }[];
}

export interface ComplyAdvantageAMLMatch {
  id: number;
  hit: ComplyAdvantageHit;
}

export interface ComplyAdvantageComplianceReport {
  birth_year: number;
  created_at: string;
  full_name: string;
  id: string;
  vendor_aml_matches: ComplyAdvantageAMLMatch[];
}

export interface LexisNexisAMLMatch {
  birth_date: string;
  first_name: string;
  hit_type: string;
  id: number;
  last_name: string;
  list_name: string;
  list_number: string;
  match_score: number;
  nationality: string;
  reason: string;
}

export interface LexisNexisComplianceReport {
  created_at: string;
  first_name: string;
  id: string;
  last_name: string;
  passport_number: string;
  vendor_aml_matches: LexisNexisAMLMatch[];
}

export type ComplianceReport =
  | ({ vendor: "comply_advantage" } & ComplyAdvantageComplianceReport)
  | ({ vendor: "lexis_nexis" } & LexisNexisComplianceReport);

export interface OctopusVerificationCase {
  compliance_reports: ComplianceReport[];
  review_reasons: ReviewReason[];
}

export type VerificationCase = VerificationCaseV1 & Partial<Person> & Partial<Institution> & OctopusVerificationCase;

export const statusLabels = ["Incomplete", "Approved", "Pending", "Contacted", "Rejected"] as const;

export type StatusLabel = (typeof statusLabels)[number];

export const vcStatus = (vc: VerificationCaseV1): StatusLabel => {
  if (vc.journey_completed === false) {
    return "Incomplete";
  }

  if (vc.status === "done" && vc.credential === "approved") {
    return "Approved";
  }

  if (vc.status === "contacted" && vc.credential === "pending") {
    return "Contacted";
  }

  if (vc.status === "done" && vc.credential === "rejected") {
    return "Rejected";
  }

  return "Pending";
};

export const vcRejectedOrContacted = (vc: VerificationCase): boolean => {
  if (!vc.journey_completed) return false;

  if (vc.status === "contacted" && vc.credential === "pending") return true;
  if (vc.status === "done" && vc.credential === "rejected") return true;

  return false;
};

export const merge = (megalodonVC: VerificationCaseV1, octopusVC: OctopusVerificationCase): VerificationCase => ({
  ...megalodonVC,
  ...megalodonVC.details,
  compliance_reports: octopusVC.compliance_reports,
  review_reasons: octopusVC.review_reasons,
});
