import { AxiosInstance, isAxiosError } from "axios";
import { Api as MegalodonApi } from "../api/megalodon";
import { type Application, Api as CatfishApi } from "../api/catfish";
import config from "./config";
import session from "lib/session";
import { memoize } from "lodash-es";

const DEFAULT_SCOPE = "client.verification_case:list client.verification_case:read";

export const catfishApi = new CatfishApi({
  baseURL: config.CATFISH_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchApplicationToken = memoize(
  (application: Application, scopes: string) =>
    catfishApi.oauth.getToken({
      grant_type: "client_credentials",
      scope: scopes,
      client_id: application.uid,
      client_secret: application.secret,
    }),
  (application, scopes) => `${application.uid} ${application.secret} ${scopes}`,
);

export const megalodonApi = new MegalodonApi({
  baseURL: config.MEGALODON_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const setAuthorizationHeader = (client: AxiosInstance): number =>
  client.interceptors.request.use((config) => {
    const token = session.getToken();

    if (token && config.headers && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });

setAuthorizationHeader(megalodonApi.instance);
setAuthorizationHeader(catfishApi.instance);

const MAX_RETRIES = 3;

export const megalodonApiWithApplication = memoize(
  (application: Application, scope: string = DEFAULT_SCOPE) => {
    const ret = new MegalodonApi({
      baseURL: config.MEGALODON_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    ret.instance.interceptors.request.use(
      async (config) => {
        const {
          data: { access_token },
        } = await fetchApplicationToken(application, scope);
        config.headers["Authorization"] = `Bearer ${access_token}`;
        return config;
      },
      null,
      { synchronous: false },
    );

    ret.instance.interceptors.response.use(
      (response) => response,
      async (error: unknown) => {
        if (!isAxiosError(error)) return Promise.reject(error);

        if (isAxiosError(error) && error.response?.status === 401 && fetchApplicationToken.cache.clear) {
          fetchApplicationToken.cache.clear();

          // 401 expired or bad token
          const config = error.config;

          // Set a retry count
          // @ts-expect-error This is not typed
          if (!config.__retryCount) config.__retryCount = 0;

          // @ts-expect-error This is not typed
          if (config.__retryCount < MAX_RETRIES) {
            // @ts-expect-error This is not typed
            await new Promise((resolve) => setTimeout(resolve, config.__retryCounter * 1000));

            // @ts-expect-error This is not typed
            config.__retryCount++;

            // @ts-expect-error This is not typed
            return ret.instance(config);
          }
        }

        return Promise.reject(error);
      },
    );

    return ret;
  },
  (application, scopes) => `${application.uid} ${application.secret} ${scopes}`,
);
