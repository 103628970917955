import axios, { AxiosInstance, Method } from "axios";
import { OctopusVerificationCase } from "lib/verification_case";

import config from "lib/config";
import { barracuda, octopus, sprat, HTTPResponse } from "lib/http";

import { type Application } from "../api/catfish";
import { fetchApplicationToken } from "./apiv2";

export const REDIRECT_URI = `${config.BASE_URL}/session`;

export const fetchToken = (code: string): HTTPResponse<{ access_token: string }> =>
  sprat.post("/oauth/token", {
    code,
    client_id: config.OAUTH_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    grant_type: "authorization_code",
  });

const fetchWithApplicationToken =
  (animal: AxiosInstance) =>
  async <T>(
    application: Application,
    scope: string,
    endpoint: string,
    method?: Method,
    data?: Record<string, unknown>,
  ): HTTPResponse<T> => {
    const doWork = (): HTTPResponse<T> =>
      fetchApplicationToken(application, scope).then(({ data: { access_token } }) =>
        animal.request({
          url: endpoint,
          method: method || "get",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          data,
        }),
      );

    return doWork().catch((e) => {
      if (axios.isAxiosError(e) && e.response?.status !== 401) return Promise.reject(e);

      if (fetchApplicationToken.cache.clear) {
        fetchApplicationToken.cache.clear();
      }

      return doWork();
    });
  };

const fetchOctopusWithApplicationToken = fetchWithApplicationToken(octopus);

export const fetchOctopusVerificationCase = async (
  application: Application,
  scopedUserId: string,
  level: string,
): Promise<OctopusVerificationCase> => {
  const { data } = await fetchOctopusWithApplicationToken<OctopusVerificationCase>(
    application,
    "client.verification_case:read",
    `/api/verification-cases/${scopedUserId}/${level}`,
  );

  return data;
};

export interface Webhook {
  id: string;
  client_id: string;
  notification_type: string;
  callback_url: string;
  secret_token: string;
  active: boolean;
}

export const webhooks = {
  all: async () => {
    const { data } = await barracuda.get<Webhook[]>("/subscriptions");

    return data;
  },

  create: async (webhook: Partial<Webhook>) => {
    const { data } = await barracuda.post<Webhook>("/subscriptions", { subscription: webhook });

    return data;
  },
};
