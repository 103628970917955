import React, { useEffect } from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { pageRefresh } from "lib/browser";

import ProtectedLayout from "layouts/Protected";
import RequiresPolicyAcceptanceLayout from "layouts/RequiresPolicyAcceptance";
import ApplicationFetcherLayout from "layouts/ApplicationFetcher";

import App from "components/App";

import Home from "components/pages/Home";
import Session from "components/pages/Session";
import BlowUp from "components/pages/BlowUp";
import NotFound from "components/pages/NotFound";

import ApplicationsList from "components/pages/Applications/List";
import ApplicationNew from "components/pages/Applications/New";
import ApplicationUsers from "components/pages/Applications/Users";
import ApplicationDetail from "components/pages/Applications/Detail";
import ApplicationStats from "components/pages/Applications/Stats";
import ApplicationEdit from "components/pages/Applications/Edit";

import CredentialDetail from "components/pages/Credential/Detail";
import VerificationCaseDetail from "components/pages/VerificationCase/Detail";

import { dataProcessingAgreement } from "lib/nautilusLinks";

const DataProcessingAgreement = () => {
  useEffect(() => {
    pageRefresh(dataProcessingAgreement);
  }, []);

  return null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/" element={<Home />} />
      <Route path="/session" element={<Session />} />

      <Route element={<ProtectedLayout />}>
        <Route element={<RequiresPolicyAcceptanceLayout />}>
          <Route path="/apps/new" element={<ApplicationNew />} />

          <Route element={<ApplicationFetcherLayout />}>
            <Route path="/apps" element={<ApplicationsList />} />
            <Route path="/apps/:appId" element={<ApplicationDetail />} />
            <Route path="/apps/:appId/team" element={<ApplicationUsers />} />
            <Route path="/apps/:appId/edit" element={<ApplicationEdit />} />
            <Route path="/apps/:appId/stats" element={<ApplicationStats />} />
            <Route path="/apps/:appId/users/:verificationCaseId" element={<VerificationCaseDetail />} />
            <Route path="/apps/:appId/credentials/:credentialId" element={<CredentialDetail />} />
          </Route>
        </Route>
      </Route>

      <Route path="/blow-up" element={<BlowUp />} />

      <Route path="/documents/data-processing-agreement" element={<DataProcessingAgreement />} />

      <Route element={<NotFound />} path="*" />
    </Route>,
  ),
);

export default router;
