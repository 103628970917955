import React, { useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { stringify } from "qs";
import { i18n } from "@lingui/core";
import { Trans, Plural, t } from "@lingui/macro";
import { Link, useNavigate } from "react-router-dom";
import { Confirmation, Modal, ExternalLink } from "@trustfractal/seabed";

import { goBackUrl } from "lib/browser";
import config from "lib/config";
import { Webhook } from "lib/api";

import { resetCredentials, revokeAccessTokens, selectApplication } from "slices/applications";
import { saveWebhook } from "actions/webhooks";

import LoadingSpinner from "components/common/LoadingSpinner";
import CopyableText from "components/common/CopyableText";

import WebhookForm from "components/forms/Webhook";
import VerificationCasesList from "components/pages/VerificationCase/List";
import CredentialsList from "components/pages/Credential/List";

import { RootState } from "store";
import { compose } from "redux";
import { withRouter } from "lib/withRouter";
import { AxiosError } from "axios";
import { selectCurrentUser } from "slices/currentUser";
import { megalodonApiWithApplication } from "lib/apiv2";
import { type Export } from "api/megalodon";

type NotificationType =
  | "verification_approved"
  | "verification_rejected"
  | "verification_contacted"
  | "credential_shared"
  | "email_changed";
type NotificationTypesTranslationsType = {
  [key in NotificationType]: { title: string; description: string };
};

const notificationTypesTranslations = (): NotificationTypesTranslationsType => ({
  verification_approved: {
    title: t({
      id: "webhook.notification_type.verification_approved.title",
      message: "Verification approved",
    }),
    description: t({
      id: "webhook.notification_type.verification_approved.description",
      message: `Triggered when an authorized user gets approved
        according to the requested level`,
    }),
  },
  verification_contacted: {
    title: t({
      id: "webhook.notification_type.verification_contacted.title",
      message: "User contacted",
    }),
    description: t({
      id: "webhook.notification_type.verification_contacted.description",
      message: `Triggered when a user is contacted for more information`,
    }),
  },
  verification_rejected: {
    title: t({
      id: "webhook.notification_type.verification_rejected.title",
      message: "Verification rejected",
    }),
    description: t({
      id: "webhook.notification_type.verification_rejected.description",
      message: `Triggered when an authorized user does not meet
        the criteria for the verification of the requested level`,
    }),
  },
  credential_shared: {
    title: t({
      id: "webhook.notification_type.credential_shared.title",
      message: "idOS credential shared",
    }),
    description: t({
      id: "webhook.notification_type.credential_shared.description",
      message: `Triggered when a credential is shared on behalf of the user`,
    }),
  },
  email_changed: {
    title: t({
      id: "webhook.notification_type.email_changed.title",
      message: "Users' email changed",
    }),
    description: t({
      id: "webhook.notification_type.email_changed.description",
      message: `Triggered when an user confirmed their email address change`,
    }),
  },
});

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const loginLinkSnippet = ({ client_id, redirect_uri, addons }: { client_id: string; redirect_uri: string; addons: string[] }) =>
  `${config.NAUTILUS_BASE_URL}/authorize?${stringify({
    client_id,
    redirect_uri,
    response_type: "code",
    scope: `contact:read ${addons.map((addon) => `verification.${addon}:read verification.${addon}.details:read`).join(" ")}`,
  })}`;

export interface ApplicationViewProps {
  router: {
    params: {
      appId: string;
    };
  };
}

const mapStateToProps = (state: RootState, props: ApplicationViewProps) => ({
  application: selectApplication(state, props.router.params.appId),
  webhooks: state.webhooks?.filter((wh) => wh.client_id === props.router.params.appId),
  scopedUserId: selectCurrentUser(state)?.uid,
});

const mapDispatchToProps = {
  resetCredentials,
  revokeAccessTokens,
  saveWebhook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ApplicationViewPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationView({
  application,
  webhooks,
  scopedUserId,
  resetCredentials,
  saveWebhook,
  revokeAccessTokens,
}: ApplicationViewPropsFromRedux) {
  const [confirmation, setConfirmation] = useState<string | null>(null);
  const [webhookType, setWebhookType] = useState<NotificationType | null>(null);
  const [webhookOpen, setWebhookOpen] = useState(false);
  const [lastVCExportRequest, setLastVCExportRequest] = useState<Export | null>(null);
  const [hasRequestedVCExport, setHasRequestedVCExport] = useState(false);
  const [lastCredentialExportRequest, setLastCredentialExportRequest] = useState<Export | null>(null);
  const [hasRequestedCredentialExport, setHasRequestedCredentialExport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (application) {
      void megalodonApiWithApplication(application)
        .verificationCases.getVerificationCasesExports()
        .then(({ data }) => {
          const lastExport = data.length > 0 ? data[0] : null;
          setLastVCExportRequest(lastExport);
          setHasRequestedVCExport(lastExport?.status === "pending");
        })
        .catch((err: AxiosError) => {
          if (err?.response?.status !== 404) {
            // 404 Not Found means there is no previous export request --- that's fine
            alert("An error has ocurred while fetching the last export request");
          }
        });

      void megalodonApiWithApplication(application, "client.credential:list client.credential:read")
        .credentials.getCredentialsExport()
        .then(({ data }) => {
          const lastExport = data.length > 0 ? data[0] : null;
          setLastCredentialExportRequest(lastExport);
          setHasRequestedCredentialExport(lastExport?.status === "pending");
        })
        .catch((err: AxiosError) => {
          if (err?.response?.status !== 404) {
            // 404 Not Found means there is no previous export request --- that's fine
            alert("An error has ocurred while fetching the last export request");
          }
        });
    }
  }, [application]);

  const confirmationProps = useMemo(() => {
    if (!application || !confirmation) {
      return {
        open: false,
        onClose: () => setConfirmation(null),
      };
    }

    const props = {
      resetCredentials: {
        title: t({
          id: "confirmation.reset_client_secret.title",
          message: "Reset client secret",
        }),
        children: t({
          id: "confirmation.reset_client_secret.disclaimer",
          message: "Please confirm that you want to reset secret",
        }),
        onConfirm: () => application && resetCredentials(application.uid),
      },
      revokeAccessTokens: {
        title: t({
          id: "confirmation.revoke_access_tokens.title",
          message: "Revoke access tokens",
        }),
        children: t({
          id: "confirmation.revoke_access_tokens.disclaimer",
          message: "Please confirm that you want to revoke access tokens for all users",
        }),
        onConfirm: () => revokeAccessTokens(application.uid),
      },
    }[confirmation];

    return {
      open: confirmation !== null,
      onClose: () => setConfirmation(null),
      ...props,
    };
  }, [application, confirmation]);

  const hideWebhookForm = () => {
    setWebhookOpen(false);
    setWebhookType(null);
  };

  const showWebhookForm = (webhookType: NotificationType) => {
    setWebhookOpen(true);
    setWebhookType(webhookType);
  };

  const webhookSubmitHandler = (values: Partial<Webhook>) => {
    void saveWebhook({ ...values }).then(hideWebhookForm);
  };

  const webhookFormProps = useMemo(() => {
    if (!webhookOpen || !application || !webhooks || !webhookType) return null;

    const { title, description } = notificationTypesTranslations()[webhookType];
    const webhook = webhooks.find((wh) => wh.notification_type === webhookType);

    return {
      modal: {
        title: "Edit webhook",
        className: "modal--webhook",
        cssTransitionProps: { appear: true },
        open: webhookOpen,
        onClose: hideWebhookForm,
      },
      form: {
        title,
        description,
        initialValues: {
          client_id: application.uid,
          notification_type: webhookType,
          ...webhook,
        },
        enableReinitialize: true,
        touchOnChange: true,
        onSubmit: webhookSubmitHandler,
      },
    };
  }, [application, webhookType, webhookOpen, webhooks]);

  const openExternalLink = (url: string) => window.open(url, "_blank");

  useEffect(() => {
    if (!application) navigate("/apps");
  }, []);

  if (!application) return null;

  const {
    description,
    homepage_url,
    image_url,
    name,
    redirect_uri,
    secret,
    active,
    uid: client_id,
    users_count,
    verification_redirect_url,
    my_role,
    options: { blocked_nationality_countries = [], blocked_residency_countries = [] },
  } = application;

  const applicationLoginLinkSnippet = (addons: string[]) => loginLinkSnippet({ client_id, redirect_uri, addons });

  const exportVCs = () => {
    if (!application || !scopedUserId) return;

    void megalodonApiWithApplication(application)
      .verificationCases.createVerificationCasesExports({ scoped_user_id: scopedUserId })
      .then(({ data }) => {
        setLastVCExportRequest(data);
        setHasRequestedVCExport(data?.status === "pending");
      })
      .catch((_error) => {
        alert(
          t({
            id: "application.csv_export.error",
            message: "An unexpected error has occured. Please try again later.",
          }),
        );
        setHasRequestedVCExport(false);
      });
  };

  const exportCreds = () => {
    if (!application || !scopedUserId) return;

    void megalodonApiWithApplication(application, "client.credential:list client.credential:read")
      .credentials.createCredentialsExports({ scoped_user_id: scopedUserId })
      .then(({ data }) => {
        setLastCredentialExportRequest(data);
        setHasRequestedCredentialExport(data?.status === "pending");
      })
      .catch(() => {
        alert(
          t({
            id: "application.csv_export.error",
            message: "An unexpected error has occured. Please try again later.",
          }),
        );
        setHasRequestedCredentialExport(false);
      });
  };

  const lastExportRequestInfo = (req: Export) => {
    const exportDate = (
      <Trans id="application.csv_export.last_request">Last export request: {i18n.date(req.created_at, DATE_FORMAT)}</Trans>
    );
    let description;
    switch (req.status) {
      case "pending":
        description = (
          <Trans id="application.csv_export.pending">
            The export request is still being processed. Please check your e-mail and refresh this page when the export is complete.
          </Trans>
        );
        break;
      case "done":
        description = (
          <Trans id="application.csv_export.finished">
            The export request is finished. Click{" "}
            <ExternalLink className="application__export__download-link" href={req?.file_url}>
              here
            </ExternalLink>{" "}
            to download it.
          </Trans>
        );
        break;
      case "failed":
        description = (
          <Trans id="application.csv_export.failed">
            The export request failed. Please contact our support team at{" "}
            <ExternalLink href="mailto:support@fractal.id">support@fractal.id</ExternalLink> to learn more.
          </Trans>
        );
        break;
      default:
        break;
    }
    return (
      <>
        <p>{exportDate}</p>
        <p>{description}</p>
      </>
    );
  };

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application.details">Integration details</Trans>
        </h2>

        <div className="btn-group">
          {my_role && my_role === "admin" && (
            <Link className="btn primary" to="team">
              <Trans id="applications.team">Team</Trans>
            </Link>
          )}

          <Link className="btn primary" to="edit">
            <Trans id="applications.edit">Edit</Trans>
          </Link>
        </div>
      </div>

      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>

        <div className="application__card">
          <div className="application__card--header">{name}</div>
          <div className="application__card--body">
            <div className="annotated-icon">
              <div className="icon">{image_url && <img src={image_url} />}</div>
              <div className="description">{description}</div>
            </div>

            <dl>
              <dt>
                <Trans id="application.status">Status</Trans>
              </dt>
              <dd>
                {active && <Trans id="application.active">Active</Trans>}
                {!active && (
                  <Trans id="application.inactive">
                    Integration is not active, please reach out to your account manager for activation.
                  </Trans>
                )}
              </dd>

              <dt>
                <Trans id="application.homepage_url">Homepage URL</Trans>
              </dt>
              <dd>
                <ExternalLink href={homepage_url}>{homepage_url}</ExternalLink>
              </dd>

              <dt>
                <Trans id="application.redirect_uri">Authorization callback URL</Trans>
              </dt>
              <dd>
                <CopyableText text={redirect_uri} />
              </dd>
              <dt>
                <Trans id="application.verification_redirect_url">Verification Redirect URL</Trans>
              </dt>
              <dd>
                {verification_redirect_url ? (
                  <ExternalLink href={verification_redirect_url}>{verification_redirect_url}</ExternalLink>
                ) : (
                  <Trans id="application.verification_redirect_url.not_set">No URL set</Trans>
                )}
              </dd>
              <dt>
                <Trans id="application.blocked_nationality_countries">Blocked or partially blocked nationality countries</Trans>
              </dt>
              <dd>
                {blocked_nationality_countries.length === 0 ? (
                  <Trans id="application.blocked_countries.not_set">No blocked countries set</Trans>
                ) : (
                  blocked_nationality_countries.join(", ")
                )}
              </dd>
              <dt>
                <Trans id="application.blocked_residency_countries">Blocked or partially blocked residency countries</Trans>
              </dt>
              <dd>
                {blocked_residency_countries.length === 0 ? (
                  <Trans id="application.blocked_countries.not_set">No blocked countries set</Trans>
                ) : (
                  blocked_residency_countries.join(", ")
                )}
              </dd>
            </dl>
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.finish_integration.title">Finish integration</Trans>
          </div>

          <div className="application__card--body">
            <ol className="numbered">
              <li>
                <Trans id="application.finish_integration.choose_kyc">
                  Choose the <ExternalLink href="https://docs.developer.fractal.id/kyc-levels">KYC level</ExternalLink> that is right for
                  you.
                </Trans>
              </li>
              <li>
                <Trans id="application.finish_integration.configure_url">
                  Configure your integration URLs as per the{" "}
                  <ExternalLink href="https://docs.developer.fractal.id/user-integration/user-authorization">
                    user authorization documentation
                  </ExternalLink>{" "}
                  with your Client ID and Client Secret found below.
                </Trans>
              </li>
              <li>
                <Trans id="application.finish_integration.verify_integration">
                  Make sure you&apos;re{" "}
                  <ExternalLink href="https://docs.developer.fractal.id/user-integration/user-authorization#obtaining-an-access-token">
                    fully integrated
                  </ExternalLink>{" "}
                  or you won&apos;t be able to see user data.
                </Trans>
              </li>
              <li>
                <Trans id="application.finish_integration.start_verifying">
                  Send your users to the URL generated above and start getting them verified!
                </Trans>
              </li>
              <li>
                <Trans id="application.finish_integration.configure_webhooks">
                  Optionally{" "}
                  <ExternalLink href="https://docs.developer.fractal.id/user-integration/webhooks">configure webhooks</ExternalLink>.
                </Trans>
              </li>
              <li>
                <Trans id="application.finish_integration.retrieve_information">
                  Follow{" "}
                  <ExternalLink href="https://docs.developer.fractal.id/user-integration/user-information-retrieval">
                    these instructions
                  </ExternalLink>{" "}
                  to retrieve information on your users.
                </Trans>
              </li>
              {}
            </ol>

            <dl className="application__card__snippet">
              <dt>
                <Trans id="application.snippet.basic+liveness">Your ID Basic URL:</Trans>
              </dt>
              <dd>
                <code>{applicationLoginLinkSnippet(["basic", "liveness"])}</code>
              </dd>
            </dl>
          </div>

          <div className="application__card--footer">
            <button className="btn btn-grey" onClick={() => openExternalLink("https://docs.developer.fractal.id")}>
              <Trans id="application.actions.view_detailed_documentation">View detailed documentation</Trans>
            </button>
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.api_access">API Access</Trans>
          </div>
          <div className="application__card--body">
            <dl>
              <dt>
                <Trans id="application.uid">Client ID</Trans>
              </dt>
              <dd>
                <CopyableText text={client_id} />
              </dd>

              <dt>
                <Trans id="application.secret">Client Secret</Trans>
              </dt>
              <dd>{secret && <CopyableText text={secret} />}</dd>
            </dl>
          </div>
          <div className="application__card--footer">
            <button className="btn btn-grey" onClick={() => setConfirmation("revokeAccessTokens")}>
              <Trans id="application.actions.revoke_all_user_access">Revoke access for all users</Trans>
            </button>

            <button className="btn btn-grey" onClick={() => setConfirmation("resetCredentials")}>
              <Trans id="application.actions.reset_client_secret">Reset client secret</Trans>
            </button>
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.webhooks">Webhooks</Trans>
          </div>

          <div className="application__card--body">
            {!webhooks && <LoadingSpinner />}

            {webhooks &&
              Object.entries(notificationTypesTranslations()).map(([notification_type, { title }]) => {
                const webhook = webhooks.find((wh) => wh.notification_type === notification_type);

                return (
                  <div key={notification_type} className={`application__card__webhook webhook-${notification_type}`}>
                    <div className="application__card__webhook__header">
                      <h6>{title}</h6>

                      <button
                        className="btn btn-grey"
                        data-testid={`edit-webhook-${notification_type}`}
                        onClick={() => showWebhookForm(notification_type as NotificationType)}
                      >
                        <Trans id="application.edit">Edit</Trans>
                      </button>
                    </div>

                    {webhook ? (
                      <span className={webhook.active ? "" : "webhook-inactive"}>
                        <CopyableText text={webhook.callback_url} />
                        {!webhook.active && (
                          <>
                            {" "}
                            - <Trans id="webhook.inactive">inactive</Trans>
                          </>
                        )}
                      </span>
                    ) : (
                      <span className="webhook-inactive">
                        <Trans id="webhook.not_set">Not set</Trans>
                      </span>
                    )}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.statistics">Statistics</Trans>
          </div>
          <div className="application__card--body">
            <div className="users-count">
              <span>{users_count} </span>
              <Plural id="application.edit.users_count" value={users_count ?? 0} one="authorized user" other="authorized users" />
              <br />
              <small>
                This only counts authorized users this application obtained an access token for.
                <br />
                Please consult our{" "}
                <ExternalLink href="https://docs.developer.fractal.id/kyc-levels">documentation on access tokens</ExternalLink> for more
                information.
              </small>
            </div>
          </div>
          <div className="application__card--footer">
            <Link to="stats" className="btn btn-grey">
              <Trans id="application.view_detailed_stats">View detailed stats</Trans>
            </Link>
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.user_explorer.title">User Explorer</Trans>
            <p>
              List of verifications for this application.
              <br />
              <small>
                Only shows users who authorized at least one{" "}
                <ExternalLink href="https://docs.developer.fractal.id/oauth-2.0-scopes#user-kyc-scopes">KYC scope</ExternalLink> (e.g.{" "}
                <code>verification.basic:read</code>) for this application.
              </small>
            </p>
            <p>
              <strong>Status</strong>
              <br />
              <small>
                <strong>Incomplete:</strong> User hasn&apos;t finished the KYC journey for this level. <strong>Pending:</strong> User has
                been contacted for further information; in some cases, it could also mean their verification is still queued.{" "}
                <strong>Approved:</strong> Verification approved for this level. <strong>Rejected:</strong> Verification rejected for this
                level.
              </small>
            </p>
          </div>
          <div className="application__card--body">
            <VerificationCasesList application={application} />
          </div>
          <div className="application__card--footer">
            <button className="btn primary" onClick={exportVCs} disabled={hasRequestedVCExport}>
              {!hasRequestedVCExport && <Trans id="application.csv_export.export">Export CSV</Trans>}
              {hasRequestedVCExport && <Trans id="application.csv_export.export_requested">CSV export requested</Trans>}
            </button>
            {lastVCExportRequest && <div>{lastExportRequestInfo(lastVCExportRequest)}</div>}
          </div>
        </div>

        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application.credential_explorer.title">Credential Explorer</Trans>
            <p>List of successful Credentials generated for this application.</p>
          </div>
          <div className="application__card--body">
            <CredentialsList application={application} />
          </div>
          <div className="application__card--footer">
            <button className="btn primary" onClick={exportCreds} disabled={hasRequestedCredentialExport}>
              {!hasRequestedCredentialExport && <Trans id="application.csv_export.export">Export CSV</Trans>}
              {hasRequestedCredentialExport && <Trans id="application.csv_export.export_requested">CSV export requested</Trans>}
            </button>
            {lastCredentialExportRequest && <div>{lastExportRequestInfo(lastCredentialExportRequest)}</div>}
          </div>
        </div>

        <Confirmation {...confirmationProps} />

        {webhookFormProps && (
          <Modal {...webhookFormProps.modal}>
            <WebhookForm {...webhookFormProps.form} />
          </Modal>
        )}
      </div>
    </div>
  );
}

export default compose<() => React.ReactElement>(withRouter, connector)(ApplicationView);
