import { type ApplicationUserRequest, type ApplicationUser } from "api/catfish";
import { createAppSlice } from "./util";
import { catfishApi } from "lib/apiv2";

export interface ApplicationUsersState {
  loading: { [key: string]: boolean };
  users: { [key: string]: ApplicationUser[] };
}

const initialState: ApplicationUsersState = {
  users: {},
  loading: {},
};

const applicationsSlice = createAppSlice({
  name: "applicationUsers",
  initialState,
  selectors: {
    selectApplicationUsers: (state, appId?: string): ApplicationUser[] | undefined => {
      return appId ? state.users[appId] : undefined;
    },
    selectApplicationUsersLoading: (state, appId?: string) => (appId ? state.loading[appId] : undefined),
  },
  reducers: (create) => ({
    createApplicationUser: create.asyncThunk(
      async (payload: ApplicationUserRequest & { appId: string }) => {
        const { appId, ...user } = payload;
        const { data } = await catfishApi.applications.createApplicationUser(appId, user);
        return data;
      },
      {
        pending: (state, action) => {
          state.loading[action.meta.arg.appId] = true;
        },
        settled: (state, action) => {
          state.loading[action.meta.arg.appId] = false;
        },
        fulfilled: (state, action) => {
          state.users[action.meta.arg.appId] = [action.payload, ...state.users[action.meta.arg.appId]];
        },
      },
    ),
    fetchApplicationUsers: create.asyncThunk(
      async (appId: string) => {
        const { data } = await catfishApi.applications.getApplicationUsers(appId);
        return data;
      },
      {
        pending: (state, action) => {
          state.loading[action.meta.arg] = true;
        },
        settled: (state, action) => {
          state.loading[action.meta.arg] = false;
        },
        fulfilled: (state, action) => {
          state.users[action.meta.arg] = action.payload;
        },
      },
    ),
    deleteApplicationUser: create.asyncThunk(
      async (payload: { appId: string; userId: string }) => {
        const { appId, userId } = payload;
        const { data } = await catfishApi.applications.deleteApplicationUser(appId, userId);
        return data;
      },
      {
        pending: (state, action) => {
          state.loading[action.meta.arg.appId] = true;
        },
        settled: (state, action) => {
          state.loading[action.meta.arg.appId] = false;
        },
        fulfilled: (state, action) => {
          state.users[action.meta.arg.appId] = state.users[action.meta.arg.appId].filter((user) => user.uid !== action.meta.arg.userId);
        },
      },
    ),
  }),
});

export const { selectApplicationUsers, selectApplicationUsersLoading } = applicationsSlice.selectors;

export const { fetchApplicationUsers, createApplicationUser, deleteApplicationUser } = applicationsSlice.actions;

export default applicationsSlice.reducer;
