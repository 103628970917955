import env from "lib/env";

interface Config {
  BASE_URL: string;
  CATFISH_BASE_URL: string;
  OAUTH_CLIENT_ID: string;
  MEGALODON_BASE_URL: string;
  SENTRY_DSN: string;
  SPRAT_BASE_URL: string;
  SENTRY_ENVIRONMENT: string;
  OCTOPUS_BASE_URL: string;
  BARRACUDA_BASE_URL: string;
  NAUTILUS_BASE_URL: string;
  ENABLED_COMPLIANCE_REPORTS: string;
  ENABLED_VERIFICATION_REDIRECT_LINK: string;
}

const config: Config = {
  ...env,
  BASE_URL: env.BASE_URL,
  CATFISH_BASE_URL: env.CATFISH_BASE_URL,
  OAUTH_CLIENT_ID: env.OAUTH_CLIENT_ID,
  MEGALODON_BASE_URL: env.MEGALODON_BASE_URL,
  SENTRY_DSN: env.SENTRY_DSN,
  SPRAT_BASE_URL: env.SPRAT_BASE_URL,
  OCTOPUS_BASE_URL: env.OCTOPUS_BASE_URL,
  SENTRY_ENVIRONMENT: env.SENTRY_ENVIRONMENT,
  BARRACUDA_BASE_URL: env.BARRACUDA_BASE_URL,
  NAUTILUS_BASE_URL: env.NAUTILUS_BASE_URL,
  ENABLED_COMPLIANCE_REPORTS: env.ENABLED_COMPLIANCE_REPORTS,
  ENABLED_VERIFICATION_REDIRECT_LINK: env.ENABLED_VERIFICATION_REDIRECT_LINK,
};

export default config;
