import { i18n } from "@lingui/core";

export const availableLanguages = [{ value: "en", label: "English" }];

const defaultLanguage = "en";
const SUPPORTED_LANGUAGES = availableLanguages.map((l) => l.value);

// https://gist.github.com/ksol/62b489572944ca70b4ba
export const detectLanguage = (): string => {
  const lang = ((navigator.languages && navigator.languages[0]) || navigator.language || defaultLanguage).substring(0, 2);

  return SUPPORTED_LANGUAGES.includes(lang) ? lang : defaultLanguage;
};

export const defaultLocale = detectLanguage();

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { messages } = await import(
    /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
    `locales/${locale}/messages.po`
  );

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
  i18n.load(locale, messages);
  i18n.activate(locale);
}

export default i18n;
