import React, { Fragment } from "react";
import { t } from "@lingui/macro";

import { VerificationCase, vcRejectedOrContacted } from "lib/verification_case";

export default function Reasons({ verificationCase }: { verificationCase: VerificationCase }) {
  if (!vcRejectedOrContacted(verificationCase)) return null;

  return (
    <div className="user-details__info-group">
      <h3>
        {verificationCase.status === "contacted" &&
          t({
            id: "verification_case.contacted_reason.title",
            message: "Contacted reasons",
          })}
        {verificationCase.credential === "rejected" &&
          t({
            id: "verification_case.rejected_reason.title",
            message: "Rejected reasons",
          })}
        :
      </h3>

      <section className="user-details__info-group--body">
        <dl>
          {verificationCase.review_reasons.map((reason, index) => (
            <Fragment key={index}>
              <dt>
                {t({
                  id: "verification_case.review_reason.category",
                  message: "Category",
                })}{" "}
                #{index + 1}
              </dt>
              <dd>{reason.category}</dd>
              <dt>
                {t({
                  id: "verification_case.review_reason.reason",
                  message: "Reason",
                })}{" "}
                #{index + 1}
              </dt>
              <dd>{reason.reason}</dd>
            </Fragment>
          ))}
          {verificationCase.message && (
            <>
              <dt>
                {t({
                  id: "verification_case.message.label",
                  message: "Message for user",
                })}
              </dt>
              <dd>{verificationCase.message}</dd>
            </>
          )}
        </dl>
      </section>
    </div>
  );
}
