import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { presence, email } from "redux-form-validators";
import { Trans } from "@lingui/macro";

import Input from "components/fields/Input";
import { ApplicationUserRequest } from "api/catfish";

function ApplicationUserForm(props: InjectedFormProps<ApplicationUserRequest>) {
  const { handleSubmit, submitting, valid } = props;

  return (
    <form className="form-group__inline" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">
          <Trans id="application_users.email">Email</Trans>
        </label>
        <Field component={Input} name="email" validate={[presence(), email()]} />
      </div>
      <div className="form-group">
        <label htmlFor="role">
          <Trans id="application_users.role">Role</Trans>
        </label>
        <Field component="select" name="role" validate={presence()} className="base-select">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </Field>
      </div>
      <div>
        <button type="submit" className="btn btn-grey" disabled={!valid || submitting}>
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default reduxForm<ApplicationUserRequest>({ form: "applicationUser", initialValues: { role: "user" } })(ApplicationUserForm);
