import React, { useState } from "react";
import { Field, InjectedFormProps, reduxForm, formValueSelector } from "redux-form";
import { presence, url } from "redux-form-validators";
import { Trans } from "@lingui/macro";

import config from "lib/config";
import countries from "assets/countries.json";

import Input from "components/fields/Input";
import Textarea from "components/fields/Textarea";

import { fileToBase64 } from "lib/browser";
import { urlWithLocalhostValidator, urlWithoutHashValidator } from "lib/validators";
import { useAppSelector } from "lib/hooks";
import { ApplicationFormData, CountrySelection } from "slices/applications";

export interface ApplicationFormProps {
  isEdit?: boolean;
  image_url?: string | null;
  onCancel?: () => void;
}

const sortedCountries = countries.sort((a, b) => a.label.localeCompare(b.label, "en"));

const FORM_NAME = "application";

export function ApplicationForm({
  valid,
  submitting,
  handleSubmit,
  change /* eslint-disable-line @typescript-eslint/unbound-method */,
  isEdit = false,
  image_url = null,
  onCancel = () => {},
}: ApplicationFormProps & InjectedFormProps<ApplicationFormData, ApplicationFormProps>) {
  const [imageUrl, setImageUrl] = useState<string | null>(image_url);

  // Options filtering
  const [residencyCountriesFilter, setResidencyCountriesFilter] = useState<string>("");
  const [nationalityCountriesFilter, setNationalityCountriesFilter] = useState<string>("");

  // Current values
  const selector = formValueSelector(FORM_NAME);
  const currentResidencyCountries =
    useAppSelector((state) => selector(state, "blocked_residency_countries") as CountrySelection | undefined) || {};
  const currentNationalityCountries =
    useAppSelector((state) => selector(state, "blocked_nationality_countries") as CountrySelection | undefined) || {};

  const changeIcon = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      void fileToBase64(event.target.files[0]).then((image_url) => {
        setImageUrl(image_url as string);
        change("image_url", image_url);
      });
    }
  };

  const countriesOptions = (key: string, currentList: CountrySelection, filter?: string) =>
    sortedCountries.map((country) => {
      if (filter?.length && !currentList[country.value] && !country.label.toLowerCase().includes(filter.toLowerCase().trim())) {
        return null;
      }

      const name = `${key}[${country.value}]`;

      return (
        <div key={name}>
          <Field key={country.value} id={name} name={name} component="input" type="checkbox" />
          <label htmlFor={name}>{country.label}</label>
        </div>
      );
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group icon-preview">
        <div className="preview">{imageUrl && <img src={imageUrl} />}</div>

        <label>
          <Trans id="application.image_url">Integration Icon</Trans>
          <br />
          <span className="btn btn-grey">Upload new icon</span>
          <input type="file" accept="image/*" onChange={changeIcon} />
        </label>

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Field component={Input} name="image_url" id="image_url" type="hidden" value={image_url} />
      </div>

      <div className="form-group">
        <label htmlFor="name">
          <Trans id="application.name">Integration name</Trans>
        </label>
        <Field component={Input} name="name" id="name" validate={presence()} />
      </div>

      <div className="form-group">
        <label htmlFor="homepage_url">
          <Trans id="application.homepage_url">Homepage URL</Trans>
        </label>
        <Field component={Input} name="homepage_url" id="homepage_url" type="url" validate={[presence(), url()]} />
      </div>

      <div className="form-group">
        <label htmlFor="description">
          <Trans id="application.description">Description</Trans>
        </label>
        <Field component={Textarea} name="description" id="description" />

        <span className="notice">
          <Trans id="application.description.notice">Your users will see this before Authorizing</Trans>
        </span>
      </div>

      <div className="form-group">
        <div className="form-control form-control--inline form-control--fullwidth">
          <Field id="show_description" name="show_description" component="input" type="checkbox" />

          <label htmlFor="show_description">
            <Trans id="application.show_description">Show description</Trans>
          </label>
        </div>

        <span className="notice">
          <Trans id="application.description.show_description">
            Your users will see the description of the integration before authorizing
          </Trans>
        </span>
      </div>

      <div className="form-group">
        <label htmlFor="redirect_uri">
          <Trans id="application.redirect_uri">Authorization callback URL</Trans>
        </label>

        <Field
          component={Input}
          name="redirect_uri"
          id="redirect_uri"
          type="url"
          validate={[presence(), url(), urlWithLocalhostValidator(), urlWithoutHashValidator()]}
        />
        <span className="notice">
          <Trans id="application.redirect_uri.notice">Your users will get redirected here after authorizing your app.</Trans>
        </span>
      </div>

      {config.ENABLED_VERIFICATION_REDIRECT_LINK === "1" && (
        <div className="form-group">
          <label htmlFor="verification_redirect_url">
            <Trans id="application.verification_redirect_url">Verification Redirect URL</Trans>
          </label>

          <Field
            component={Input}
            name="verification_redirect_url"
            id="verification_redirect_url"
            type="url"
            validate={[
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
              url({ allowBlank: true } as any),
              urlWithLocalhostValidator({ allowBlank: true }),
            ]}
          />
          <span className="notice">
            <Trans id="application.verification_redirect_url.notice">Your users will be directed here after being verified.</Trans>
          </span>
        </div>
      )}

      <div className="form-group">
        <div className="countries__header">
          <label htmlFor="description">
            <Trans id="application.blocked_nationality_countries">Blocked or partially blocked nationality countries</Trans>
          </label>

          <div className="countries__search">
            <label htmlFor="countrySearch">
              <Trans id="application.blocked_countries_search">Search</Trans>:
            </label>
            <input
              id="countrySearch"
              type="text"
              value={nationalityCountriesFilter}
              onChange={(e) => setNationalityCountriesFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="countries__wrapper">
          {countriesOptions("blocked_nationality_countries", currentNationalityCountries, nationalityCountriesFilter)}
        </div>

        <span className="notice">
          <Trans id="application.blocked_countries.notice">
            If you wish to block a specific region within a country (e.g., Ontario, CA) or block a country based on a set of conditions
            (e.g., US users should only be approved if the Source of Wealth is provided), please add the relevant country to the blocklist
            and contact your sales rep. or account manager.
          </Trans>
        </span>
      </div>

      <div className="form-group">
        <div className="countries__header">
          <label htmlFor="description">
            <Trans id="application.blocked_residency_countries">Blocked or partially blocked residency countries</Trans>
          </label>

          <div className="countries__search">
            <label htmlFor="countrySearch">
              <Trans id="application.blocked_countries_search">Search</Trans>:
            </label>
            <input
              id="countrySearch"
              type="text"
              value={residencyCountriesFilter}
              onChange={(e) => setResidencyCountriesFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="countries__wrapper">
          {countriesOptions("blocked_residency_countries", currentResidencyCountries, residencyCountriesFilter)}
        </div>

        <span className="notice">
          <Trans id="application.blocked_countries.notice">
            If you wish to block a specific region within a country (e.g., Ontario, CA) or block a country based on a set of conditions
            (e.g., US users should only be approved if the Source of Wealth is provided), please add the relevant country to the blocklist
            and contact your sales rep. or account manager.
          </Trans>
        </span>
      </div>

      <div className="form-group applications__form__actions">
        {!isEdit && (
          <button type="submit" className="btn primary" disabled={!valid || submitting}>
            <Trans id="create">Create</Trans>
          </button>
        )}
        {isEdit && (
          <>
            <button type="submit" className="btn primary" disabled={!valid || submitting}>
              <Trans id="save_and_update">Save & Update</Trans>
            </button>
            <button className="btn secondary" onClick={onCancel}>
              <Trans id="cancel">Cancel</Trans>
            </button>
          </>
        )}
      </div>
    </form>
  );
}

export default reduxForm<ApplicationFormData, ApplicationFormProps>({ form: "application" })(ApplicationForm);
