import React, { Fragment } from "react";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { vcStatus, VerificationCase } from "lib/verification_case";
import { DetailsGroup, DetailsGroupOption } from "components/common/DetailsGroup";

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export default function Overview({ verificationCase }: { verificationCase: VerificationCase }) {
  const labelValueList: DetailsGroupOption[] = [
    {
      label: t({ id: "verification_case.uid.label", message: "User ID" }),
      value: verificationCase.uid,
    },
  ];

  if (verificationCase.email) {
    labelValueList.push({
      label: t({ id: "verification_case.email.label", message: "Email" }),
      value: verificationCase.email,
    });
  }

  const obsoleteEmails = verificationCase.emails?.filter((email) => email.source_type === "obsolete") ?? [];
  if (obsoleteEmails.length > 0) {
    labelValueList.push({
      label: t({ id: "verification_case.obsolete_emails.label", message: "Obsolete emails" }),
      value: obsoleteEmails.map((x) => x.address).join(", "),
    });
  }

  labelValueList.push(
    {
      label: t({ id: "verification_case.level.label", message: "Level" }),
      value: verificationCase.level,
    },
    {
      label: t({ id: "verification_case.status.label", message: "Status" }),
      value: vcStatus(verificationCase),
    },
    {
      label: t({ id: "verification_case.created_at.label", message: "KYC start date" }),
      value: i18n.date(new Date(verificationCase.created_at), DATE_FORMAT),
    },
    {
      label: t({ id: "verification_case.journey_completed_at.label", message: "KYC submit date" }),
      value: verificationCase.journey_completed_at ? i18n.date(new Date(verificationCase.journey_completed_at), DATE_FORMAT) : undefined,
    },
    {
      label: t({ id: "verification_case.updated_at.label", message: "Last update" }),
      value: i18n.date(new Date(verificationCase.updated_at), DATE_FORMAT),
    },
  );

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.overview.title",
        message: "Overview",
      })}
      labelValueList={labelValueList}
      footer={<OverviewFooter />}
    />
  );
}

function OverviewFooter() {
  const items = [
    [
      t({
        id: "verification_case.created_at.label",
        message: "KYC start date",
      }),
      t({
        id: "verification_case.created_at.description",
        message: "Date when the user started their journey",
      }),
    ],
    [
      t({
        id: "verification_case.journey_completed_at.label",
        message: "KYC submit date",
      }),
      t({
        id: "verification_case.journey_completed_at.description",
        message: `Date when the user submitted their 
        data — this is not the same as approval date`,
      }),
    ],
    [
      t({
        id: "verification_case.updated_at.label",
        message: "Last update",
      }),
      t({
        id: "verification_case.updated_at.description",
        message: "Date of the last action taken either by the user of by the Fractal team",
      }),
    ],
  ];

  return (
    <small>
      <dl>
        {items.map(([label, text], index) => (
          <Fragment key={index}>
            <dt>
              <strong>{label}</strong>:
            </dt>
            <dd>{text}</dd>
          </Fragment>
        ))}
      </dl>
    </small>
  );
}
