import React from "react";
import { t } from "@lingui/macro";

import { VerificationCase } from "lib/verification_case";
import { countryName, documentType } from "lib/labels";
import Document from "components/common/Document";
import DetailsGroup, { DetailsGroupOption } from "components/common/DetailsGroup";
import { shouldRenderCheckpoint } from "lib/review_checkpoints";
import { labels as livenessLabels } from "./shared/liveness";

export const ProofOfIdentity = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const splitLevel = verificationCase.level.split("+");

  const defaultLabelValueList: DetailsGroupOption[] = [
    {
      label: t({ id: "verification_case.full_name.label", message: "Full name" }),
      value: verificationCase.full_name,
    },
    {
      label: t({ id: "verification_case.id_doc_type.label", message: "Document type" }),
      value: verificationCase.identification_document_type ? documentType(verificationCase.identification_document_type) : "",
    },
    {
      label: t({ id: "verification_case.id_doc_number.label", message: "Document number" }),
      value: verificationCase.identification_document_number,
    },
    {
      label: t({ id: "verification_case.id_doc_country.label", message: "Document country" }),
      value: countryName(verificationCase.identification_document_country),
    },
    {
      label: t({ id: "verification_case.place_of_birth.label", message: "Place of Birth" }),
      value: verificationCase.place_of_birth,
    },
  ];

  const ProofOfIdentityWithSelfie = ({ verificationCase }: { verificationCase: VerificationCase }) => {
    const labelValueList = [
      ...defaultLabelValueList,
      {
        label: t({ id: "verification_case.id_doc_front.label", message: "Document front" }),
        value: <Document file={verificationCase.identification_document_front_file} key="identification_document_front_file" />,
      },
    ];

    if (verificationCase.identification_document_type !== "passport") {
      labelValueList.push({
        label: t({ id: "verification_case.id_doc_back.label", message: "Document back" }),
        value: <Document file={verificationCase.identification_document_back_file} key="identification_document_back_file" />,
      });
    }

    labelValueList.push({
      label: t({ id: "verification_case.id_doc_selfie.label", message: "Document selfie" }),
      value: <Document file={verificationCase.identification_document_selfie_file} key="identification_document_selfie_file" />,
    });

    return (
      <DetailsGroup
        title={t({
          id: "verification_case.poi_selfie.title",
          message: "Proof of identity with selfie",
        })}
        labelValueList={labelValueList}
      />
    );
  };

  const ProofOfIdentityWithLiveness = ({ verificationCase }: { verificationCase: VerificationCase }) => {
    const labelValueList = [
      ...defaultLabelValueList,
      {
        label: t({ id: "verification_case.id_doc_front.label", message: "Document front" }),
        value: <Document file={verificationCase.identification_document_front_file} key="identification_document_front_file" />,
      },
    ];

    if (verificationCase.identification_document_type !== "passport") {
      labelValueList.push({
        label: t({ id: "verification_case.id_doc_back.label", message: "Document back" }),
        value: <Document file={verificationCase.identification_document_back_file} key="identification_document_back_file" />,
      });
    }

    const files = livenessLabels()
      .filter(({ file }) => !!verificationCase[file])
      .map(({ label, file }) => ({
        label: t({
          id: "verification_case.liveness.*.label",
          message: `Liveness audit image - ${label}`,
        }),
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
        value: <Document file={verificationCase[file] as any} key={file} overrideMimeType="image/jpeg" />,
      }));

    labelValueList.push(...files);

    return (
      <DetailsGroup
        title={t({
          id: "verification_case.poi_liveness.title",
          message: "Proof of identity with liveness",
        })}
        labelValueList={labelValueList}
      />
    );
  };

  return (
    <>
      {shouldRenderCheckpoint(splitLevel, "proof_of_identity_selfie") && <ProofOfIdentityWithSelfie verificationCase={verificationCase} />}

      {shouldRenderCheckpoint(splitLevel, "proof_of_identity_liveness") && (
        <ProofOfIdentityWithLiveness verificationCase={verificationCase} />
      )}
    </>
  );
};

export default ProofOfIdentity;
