import { type ApplicationRequest, type Application } from "api/catfish";
import { createAppSlice } from "./util";
import { catfishApi } from "lib/apiv2";

export interface ApplicationsState {
  loading: boolean;
  applications: null | Application[];
}

const initialState: ApplicationsState = {
  applications: null,
  loading: false,
};

export type CountrySelection = { [key: string]: boolean };

export interface ApplicationFormData
  extends Omit<Application, "options" | "users_count" | "image_url" | "expirations_enabled" | "active" | "uid"> {
  blocked_nationality_countries: CountrySelection;
  blocked_residency_countries: CountrySelection;
  image_url?: string;
}

export const transformApplicationToFormData = (data: ApplicationRequest): ApplicationFormData => {
  let blocked_residency_countries = {};
  if (data.options?.blocked_residency_countries) {
    blocked_residency_countries = data.options.blocked_residency_countries.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
  }

  let blocked_nationality_countries = {};
  if (data.options?.blocked_nationality_countries) {
    blocked_nationality_countries = data.options.blocked_nationality_countries.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
  }

  return {
    ...data,
    blocked_residency_countries,
    blocked_nationality_countries,
  };
};

export const transformFormDataToRequest = (data: ApplicationFormData): ApplicationRequest => ({
  ...data,
  image_url: data.image_url?.startsWith("data:image") ? data.image_url : undefined,
  options: {
    blocked_nationality_countries: Object.entries(data.blocked_nationality_countries ?? {})
      .filter((x) => x[1])
      .map((x) => x[0]),
    blocked_residency_countries: Object.entries(data.blocked_residency_countries ?? {})
      .filter((x) => x[1])
      .map((x) => x[0]),
  },
});

const applicationsSlice = createAppSlice({
  name: "applications",
  initialState,
  selectors: {
    selectApplication: (state, uid?: string): Application | undefined => {
      if (!uid || state.applications === null) return undefined;

      return state.applications?.find((a) => a.uid === uid);
    },
  },
  reducers: (create) => ({
    createApplication: create.asyncThunk(
      async (application: ApplicationFormData) => {
        const input = transformFormDataToRequest(application);
        const { data } = await catfishApi.applications.createApplication(input);
        return data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        settled: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.applications = [...(state.applications ?? []), action.payload];
        },
      },
    ),
    updateApplication: create.asyncThunk(
      async (updateInput: ApplicationFormData & { uid: string }) => {
        const { uid, ...application } = updateInput;
        const input = transformFormDataToRequest(application);
        const { data } = await catfishApi.applications.updateApplication(uid, input);
        return data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        settled: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          if (state.applications === null) {
            state.applications = [action.payload];
          } else {
            state.applications = state.applications.map((app) => (app.uid === action.payload.uid ? action.payload : app));
          }
        },
      },
    ),
    resetCredentials: create.asyncThunk(
      async (id: string) => {
        const { data } = await catfishApi.applications.resetCredentials(id);
        return data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        settled: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          if (state.applications === null) {
            state.applications = [action.payload];
          } else {
            state.applications = state.applications.map((app) => (app.uid === action.payload.uid ? action.payload : app));
          }
        },
      },
    ),
    revokeAccessTokens: create.asyncThunk((id: string) => catfishApi.applications.revokeAccessTokens(id), {
      pending: (state) => {
        state.loading = true;
      },
      settled: (state) => {
        state.loading = false;
      },
    }),
    fetchApplications: create.asyncThunk(
      async () => {
        const { data } = await catfishApi.applications.getApplications();
        return data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        settled: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.applications = action.payload;
        },
      },
    ),
  }),
});

export const { selectApplication } = applicationsSlice.selectors;

export const { fetchApplications, createApplication, updateApplication, revokeAccessTokens, resetCredentials } = applicationsSlice.actions;

export default applicationsSlice.reducer;
