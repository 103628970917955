import React, { useEffect } from "react";

import { useAppSelector } from "lib/hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { selectCurrentUser } from "slices/currentUser";

export default function Protected() {
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, []);

  if (!currentUser) return null;

  return <Outlet />;
}
