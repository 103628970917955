import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import Select, { FilterOption } from "components/fields/Select";
import { Props as ReactSelectProps, SingleValue } from "react-select";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

import { type Application } from "../../../api/catfish";
import { useDebounce } from "lib/hooks";
import { countryName } from "lib/labels";
import { vcStatus } from "lib/verification_case";

import LoadingSpinner from "components/common/LoadingSpinner";
import DataTable from "components/common/DataTable";
import Error from "components/common/Error";

import arrow from "assets/images/arrow-large-right-orange.svg";
import { i18n } from "@lingui/core";
import { megalodonApiWithApplication } from "lib/apiv2";
import { VerificationCaseListV1, VerificationCaseV1 } from "api/megalodon";

const Header = {
  get labels() {
    return [
      ["", "5%"], // KYB/KYB column
      [
        t({
          id: "user_explorer.header.name",
          message: "Name",
        }),
        "20%",
      ],
      [
        t({
          id: "user_explorer.header.email_phone",
          message: "Email/Phone",
        }),
        "20%",
      ],
      [
        t({
          id: "user_explorer.header.country",
          message: "Country",
        }),
        "10%",
      ],
      [
        t({
          id: "user_explorer.header.level",
          message: "Level",
        }),
        "20%",
      ],
      [
        t({
          id: "user_explorer.header.status",
          message: "Status",
        }),
        "10%",
      ],
      [
        t({
          id: "user_explorer.header.created_at",
          message: "KYC start date",
        }),
        "10%",
      ],
      ["", "5%"], // Details arrow column
    ] as Array<[string, string]>;
  },
};

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const LIMIT = 20;

const TextFilter = (props: React.HTMLProps<HTMLInputElement>) => (
  <div className="form-control">
    <input
      name="filter"
      type="text"
      placeholder={t({
        id: "user_explorer.filter.text_placeholder",
        message: "Query",
      })}
      {...props}
    />
  </div>
);

type DateFilterProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
};

const DateFilter = ({ startDate, endDate, onChange }: DateFilterProps) => (
  <div className="form-control">
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate ?? undefined}
      endDate={endDate ?? undefined}
      dateFormat="dd/MM/yyyy"
      isClearable={true}
      placeholderText={t({
        id: "user_explorer.filter.datepicker_placeholder",
        message: "Date interval",
      })}
      selectsRange
    />
  </div>
);

type UserSearchSelectProps = Pick<ReactSelectProps<FilterOption>, "name"> & {
  value?: string;
  options: FilterOption[];
  onChange: (option: SingleValue<FilterOption>) => void;
};

const UserSearchSelect = ({ name, options, value, onChange }: UserSearchSelectProps) => {
  const meta = {
    error: false,
    touched: false,
    warning: false,
  };
  const input = {
    name,
    onChange,
    value,
  };

  return (
    <Select
      input={input}
      options={options}
      meta={meta}
      isMulti={false}
      isSearchable={false}
      placeholder={t({
        id: "user_explorer.filter.select_placeholder",
        message: "Filter by...",
      })}
    />
  );
};

const Reset = ({ onReset, filtering }: { onReset: () => void; filtering: boolean }) => (
  <div className="user-explorer__filter-reset">
    <button className="btn btn-grey" onClick={onReset} disabled={filtering}>
      {filtering && <Trans id="user_explorer.filter.filtering">Filtering...</Trans>}
      {!filtering && <Trans id="user_explorer.filter.reset">Reset</Trans>}
    </button>
  </div>
);

const VerificationCaseList = ({ application }: { application: Application }): JSX.Element => {
  const [verificationCases, setVerificationCases] = useState<VerificationCaseListV1 | null>(null);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [filtering, setFiltering] = useState(false);

  // text input
  const [textFilter, setTextFilter] = useState("");

  // date picker
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateFilter, setDateFilter] = useState("");
  const handleDateChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    let value = "";
    if (startDate && endDate) {
      // as per https://en.m.wikipedia.org/wiki/ISO_8601#Time_intervals
      const startDateStr = startDate.toISOString();
      // make higher bound of date interval inclusive
      const endDateStr = dayjs(endDate).add(1, "day").toISOString();
      value = `${startDateStr}/${endDateStr}`;
    }
    setDateFilter(value);
  };

  const fetchPage = (offset = 0, filter?: string, query?: string) => {
    setFiltering(true);

    megalodonApiWithApplication(application)
      .verificationCases.getVerificationCases({ query, offset, filter })
      .then(({ data }) => {
        setOffset(offset);
        setVerificationCases(data);
      })
      .catch((error) => {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        setError(error);
      })
      .finally(() => {
        setFiltering(false);
      });
  };

  const handleFilterChange = (option: SingleValue<FilterOption>) => {
    setFilter(option?.value || "");
  };

  const handleQueryChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setTextFilter(filter === "email" ? value.trim() : value);
  };

  const handleReset = () => {
    setFilter(undefined);
    setTextFilter("");
    setDateFilter("");
    setStartDate(null);
    setEndDate(null);
    setOffset(0);
  };

  const delayedTextFilter = useDebounce(textFilter, 1000);

  useEffect(() => {
    fetchPage(0, filter, filter !== "date" ? delayedTextFilter : dateFilter);
  }, [delayedTextFilter, dateFilter, filter]);

  const options = [
    {
      value: "email",
      label: t({
        id: "user_explorer.filter.email",
        message: "Email address",
      }),
    },
    {
      value: "date",
      label: t({
        id: "user_explorer.filter.date",
        message: "KYC start date",
      }),
    },
    {
      value: "name",
      label: t({
        id: "user_explorer.filter.name",
        message: "Name",
      }),
    },
    {
      value: "phone",
      label: t({
        id: "user_explorer.filter.phone",
        message: "Phone number",
      }),
    },
    {
      value: "uid",
      label: t({
        id: "user_explorer.filter.uid",
        message: "UID",
      }),
    },
    {
      value: "wallet_address",
      label: t({
        id: "user_explorer.filter.wallet_address",
        message: "Wallet address",
      }),
    },
  ];

  if (error) return <Error error={error} />;

  if (!verificationCases) return <LoadingSpinner />;

  return (
    <>
      <div className="form-group__inline">
        <UserSearchSelect name="filter-option" options={options} value={filter} onChange={handleFilterChange} />

        {filter === "date" ? (
          <DateFilter onChange={handleDateChange} startDate={startDate} endDate={endDate} />
        ) : (
          <TextFilter onChange={handleQueryChange} disabled={!filter || filtering} value={textFilter} />
        )}

        <Reset onReset={handleReset} filtering={filtering} />
      </div>

      <DataTable<VerificationCaseV1>
        headerLabels={Header.labels}
        data={verificationCases.verification_cases}
        total={verificationCases.total}
        offset={offset}
        limit={LIMIT}
        fetchPage={(offset = 0) => fetchPage(offset, filter, filter !== "date" ? delayedTextFilter : dateFilter)}
        renderRow={(vc: VerificationCaseV1) => (
          <>
            <td>{vc.primary_role === "institution" ? "KYB" : "KYC"}</td>
            <td>{vc.full_name}</td>
            <td>{vc.email || vc.phone}</td>
            <td>{countryName(vc.residential_address_country)}</td>
            <td>{vc.level}</td>
            <td>{vcStatus(vc)}</td>
            <td>{vc.created_at && i18n.date(new Date(vc.created_at), DATE_FORMAT)}</td>
            <td>
              {vcStatus(vc) !== "Incomplete" && (
                <Link to={`/apps/${application.uid}/users/${vc.id}`}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <img src={arrow} />
                </Link>
              )}
            </td>
          </>
        )}
      />
    </>
  );
};

export default VerificationCaseList;
