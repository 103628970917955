import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { detectLanguage } from "lib/i18n";

type LanguageState = string;

const initialState: LanguageState = detectLanguage();

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (_, action: PayloadAction<string>) => {
      return action.payload ?? initialState;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
