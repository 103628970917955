import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import AccountMenu from "components/AccountMenu";

export default function Header() {
  return (
    <div className="header">
      <Link to="/" className="header__logo">
        <Trans id="header.admin">Admin</Trans>
      </Link>
      <div className="mobile">
        <AccountMenu />
      </div>
    </div>
  );
}
