import { type UserV2 } from "api/megalodon";
import { setUser } from "@sentry/browser";
import { createAppSlice } from "./util";
import { megalodonApi } from "lib/apiv2";
import { gdpr, termsAndConditions } from "lib/policyAcceptance";

export interface CurrentUserState {
  current?: UserV2;
  loading: boolean;
  /* This flag initiate a first load attempt has been made */
  firstLoad: boolean;
}

const initialState: CurrentUserState = {
  current: undefined,
  loading: false,
  firstLoad: false,
};

const currentUserSlice = createAppSlice({
  name: "currentUser",
  initialState,
  selectors: {
    selectCurrentUser: (state) => state.current,
    selectCurrentUserLoading: (state) => state.loading,
    selectCurrentUserFirstLoad: (state) => state.firstLoad,
    selectCurrentUserEmail: (state) => state.current?.emails?.[0].address ?? "",
    selectCurrentUserUsername: (state): string => {
      const user = currentUserSlice.getSelectors().selectCurrentUser(state);

      if (!user) return "";

      return user.phones?.[0]?.number || user.emails?.[0]?.address || user.wallets[0]?.address;
    },
    selectCurrentUserInitials: (state): string => {
      return currentUserSlice.getSelectors().selectCurrentUserUsername(state)[0];
    },
  },
  reducers: (create) => ({
    acceptPolicy: create.asyncThunk(async (_payload, thunkApi) => {
      await megalodonApi.policyAcceptances.createPolicyAcceptance(termsAndConditions);
      await megalodonApi.policyAcceptances.createPolicyAcceptance(gdpr);

      void thunkApi.dispatch(fetchCurrentUser());
    }),
    fetchCurrentUser: create.asyncThunk(
      async () => {
        const { data } = await megalodonApi.v2.getUserV2();

        setUser({
          uid: data.uid,
        });

        return data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        settled: (state) => {
          state.loading = false;
          if (!state.firstLoad) state.firstLoad = true;
        },
        fulfilled: (state, action) => {
          state.current = action.payload;
        },
      },
    ),
  }),
});

export const {
  selectCurrentUser,
  selectCurrentUserLoading,
  selectCurrentUserEmail,
  selectCurrentUserInitials,
  selectCurrentUserUsername,
  selectCurrentUserFirstLoad,
} = currentUserSlice.selectors;

export const { fetchCurrentUser, acceptPolicy } = currentUserSlice.actions;

export default currentUserSlice.reducer;
