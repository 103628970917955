import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { logout } from "lib/session";
import { useAppSelector } from "lib/hooks";
import { selectCurrentUserUsername } from "slices/currentUser";

export default function Sidebar() {
  const username = useAppSelector(selectCurrentUserUsername);

  return (
    <div className="sidebar">
      <Link to="/apps">
        <Trans id="account_menu.applications">Integrations</Trans>
      </Link>
      <a href="https://docs.developer.fractal.id/" target="_blank" rel="noopener noreferrer">
        <Trans id="account_menu.api_docs">API documentation</Trans>
      </a>

      <div className="sidebar__menu">
        <small className="header__account">{username}</small>

        <button className="header__logout" onClick={logout}>
          <Trans id="account_menu.logout">Logout</Trans>
        </button>
      </div>
    </div>
  );
}
