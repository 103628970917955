/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApplicationUserRequest {
  /** @format email */
  email: string;
  role: ApplicationUserRole;
}

export type ApplicationUserRole = "user" | "admin";

export type ApplicationUser = ApplicationUserRequest & {
  /** @format uuid */
  uid: string;
};

export interface ConfirmEmailErr {
  email_otp?: string;
}

export interface ConfirmEmailNewResponse {
  /** Token is used to sign in an user in case of merging accounts */
  token?: string;
}

export type VerifiableWalletType = "aptos" | "gem" | "eth_personal_sign" | "near" | "sui" | "xumm";

export type VerifiableWalletCurrency = "eth" | "near" | "apt" | "xrp" | "sui" | "kar";

export interface OauthTokenRequest {
  client_id: string;
  grant_type: "password" | "client_credentials";
  client_secret?: string;
  password?: string;
  scope: string;
  username?: string;
}

export interface OauthTokenResponse {
  access_token: string;
}

export interface CreateRecoveryCodesRequest {
  code: string;
}

export interface CreateRecoveryCodesResponse {
  recovery_codes: string[];
}

export interface OauthAuthorizationRequest {
  /** @format uuid */
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  state?: string;
}

export type OauthAuthorizationResponse = OauthAuthorizationRequest & {
  status?: string;
};

export interface EmailConfirmRequest {
  otp: string;
}

export interface EmailNewRequest {
  /** @format email */
  email: string;
}

export interface EmailChangeRequest {
  /** @format email */
  email: string;
}

export interface TrackRejectionRequest {
  /** @format uuid */
  client_id: string;
  scope: string;
  state?: string;
}

export interface ConfirmTokenRequest {
  confirmation_token: string;
  code?: string;
}

export interface ConfirmTokenResponse {
  session: string;
}

export interface TotpRequest {
  code: string;
}

export interface CreateTotpResponse {
  recovery_codes: string[];
}

export interface Totp {
  totp_secret_key: string;
}

export interface AuthorizedApplication {
  /** @format uuid */
  id: string;
  name: string;
  homepage_url: string;
  image_url: string;
  scopes: string[];
  /** @format datetime */
  authorized_at: string;
}

export interface SignInRequest {
  type: "email" | "phone";
  email?: string;
  phone?: string;
  otp?: boolean;
  preferred_language?: string;
  recaptcha?: string;
  redirect_to?: string;
  marketing_consent: boolean;
  oauth_params?: OauthParams;
}

export interface WalletSignInRequest {
  type: VerifiableWalletType;
  currency: VerifiableWalletCurrency;
}

export interface WalletSignInResponse {
  /** @format uuid */
  id: string;
  currency: VerifiableWalletCurrency;
  extra: {
    url?: string;
    qr_code?: string;
  };
  status: "pending" | "success" | "failure";
  proof: string;
  signature?: string;
  /** @format uuid */
  user_id?: string;
  type: VerifiableWalletType;
  /** @format uuid */
  nonce: string;
  message: string;
}

export interface WalletSignInConfirmRequest {
  address: string;
  nonce: string;
  signature: string;
  public_key?: string;
  type: VerifiableWalletType;
  block_id?: string;
  success?: boolean;
  callback_url?: string;
  oauth_params?: OauthParams;
}

export interface WalletSignInConfirmResponse {
  confirmation_token: string;
  /** @format uuid */
  authorization_request_id?: string;
}

export interface Account {
  /** @format uuid */
  user_id: string;
  otp_methods: "totp"[];
  total_recovery_codes: number;
  recovery_codes_left: number;
  authorization_requests: AuthorizationRequest[];
}

export interface AuthorizationRequest {
  action: "request" | "approval" | "rejection";
  application: ClientApplication;
  /** @format uuid */
  application_id: string;
  /** @format datetime */
  created_at: string;
  /** @format uuid */
  id: string;
  redirect_uri?: string;
  referrer?: string;
  referral?: string;
  response_type: "code";
  scopes: string;
  state?: string;
  options: AuthorizationRequestOptions;
}

export interface AuthorizationRequestOptions {
  user_role?: "person" | "institution";
  ensure_wallet?: string;
}

export interface ApplicationRequest {
  description?: string;
  homepage_url: string;
  image_url?: string;
  name: string;
  options?: ApplicationRequestOptions;
  show_description: boolean;
  verification_redirect_url?: string;
  redirect_uri: string;
}

export interface ApplicationRequestOptions {
  blocked_nationality_countries?: string[];
  blocked_residency_countries?: string[];
}

export interface Application {
  active: boolean;
  /** @format uuid */
  uid: string;
  description?: string;
  expirations_enabled: boolean;
  homepage_url: string;
  image_url?: string;
  name: string;
  options: ApplicationOptions;
  show_description: boolean;
  verification_redirect_url?: string;
  redirect_uri: string;
  secret?: string;
  users_count?: number;
  my_role?: "admin" | "user";
  secret_options?: ApplicationSecretOptions;
}

export interface ApplicationSecretOptions {
  /**
   * Configuration for wallet AML checks
   * @example {"eth":"coin","near":"base","btc":"silver"}
   */
  wallet_aml?: Record<string, string>;
  idos_dag: IdosDagChainSecretConfig[];
  idos_credentials?: "none" | "generic" | "specific";
}

export interface IdosDagChainSecretConfig {
  /** Chain type */
  type: string;
  /** Chain secret key */
  delegate_secret_key: string;
}

export interface ClientApplication {
  /** @format uuid */
  id: string;
  name: string;
  /** @format uri */
  homepage_url?: string;
  description?: string;
  /** @format uri */
  image_url?: string;
  /** @format uri */
  verification_redirect_url?: string;
  policy?: ClientPolicy;
  hide_partner_notice: boolean;
  options: ApplicationOptions;
  active: boolean;
  expirations_enabled?: boolean;
  show_description: boolean;
}

export type KycTypes = "fractal" | "sum_sub";

export interface ApplicationOptions {
  kyc?: KycTypes;
  /** Whether to split the address into separate fields */
  split_address?: boolean;
  /** Whether to split the full name into separate fields */
  split_full_name?: boolean;
  blocked_residency_countries?: string[];
  blocked_nationality_countries?: string[];
  drivers_license_blocked?: boolean;
  skip_email_collection?: boolean;
  /** The number of seconds, before user is redirected back to the application */
  redirect_timeout?: number;
  sections_to_reverify?: "residential_address"[];
  disabled_login_methods?: ("email" | "phone" | "wallet")[];
  poa_expiration_intervals?: {
    delivery_receipt?: number;
    official_documents?: number;
    official_correspondence?: number;
    utility_bill?: number;
    invalid?: number;
  };
  idos_dag?: {
    required_type: "kyc" | "human";
    required_level: string;
    chain_configs: IdosDagChainConfig[];
    enc_pub_key: string;
    near_public_key?: string;
    /** Locked until in days */
    locked_until: number;
  };
}

export interface IdosDagChainConfig {
  /** arbitrum_one, arbitrum_sepolia etc.. */
  type: string;
  /** @pattern ^0x[A-Za-z0-9]+$ */
  grantee: string;
}

export interface ClientPolicy {
  /** @format uuid */
  id: string;
  name: string;
  description?: string;
  /** @format uri */
  url?: string;
  /** @format uri */
  image_url: string;
  mandatory: boolean;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
}

export interface OauthParams {
  client_id?: string;
  state?: string;
  scope?: string;
  redirect_uri?: string;
  response_type?: string;
  referral?: string;
  user_role?: "person" | "institution";
  ensure_wallet?: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://auth.fractal.id" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Fractal Auth API
 * @version 2.0
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://auth.fractal.id
 * @contact <developers@fractal.id>
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  applications = {
    /**
     * No description
     *
     * @tags applications
     * @name GetApplications
     * @summary Get list of applications
     * @request GET:/applications
     */
    getApplications: (
      query?: {
        /** Filter by idos */
        idos?: string;
        /** Filter by inactive applications */
        inactive?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Application[], any>({
        path: `/applications`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applications
     * @name CreateApplication
     * @summary Create a new application
     * @request POST:/applications
     */
    createApplication: (data: ApplicationRequest, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/applications`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applications
     * @name UpdateApplication
     * @summary Update an existing application
     * @request PUT:/applications/{appId}
     */
    updateApplication: (appId: string, data: ApplicationRequest, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/applications/${appId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applications
     * @name ResetCredentials
     * @summary Reset application credentials
     * @request PUT:/applications/{appId}/reset-credentials
     */
    resetCredentials: (appId: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/applications/${appId}/reset-credentials`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applications
     * @name RevokeAccessTokens
     * @summary Revoke all access tokens
     * @request POST:/applications/{id}/revoke-access-tokens
     */
    revokeAccessTokens: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}/revoke-access-tokens`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applicationUsers
     * @name GetApplicationUsers
     * @summary Get all users belongs to application
     * @request GET:/applications/{appId}/users
     */
    getApplicationUsers: (appId: string, params: RequestParams = {}) =>
      this.request<ApplicationUser[], any>({
        path: `/applications/${appId}/users`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applicationUsers
     * @name CreateApplicationUser
     * @summary Create a new application user
     * @request POST:/applications/{appId}/users
     */
    createApplicationUser: (appId: string, data: ApplicationUserRequest, params: RequestParams = {}) =>
      this.request<ApplicationUser, any>({
        path: `/applications/${appId}/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags applicationUsers
     * @name DeleteApplicationUser
     * @summary Delete application user
     * @request DELETE:/applications/{appId}/users/{userId}
     */
    deleteApplicationUser: (appId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${appId}/users/${userId}`,
        method: "DELETE",
        ...params,
      }),
  };
  emails = {
    /**
     * No description
     *
     * @tags email
     * @name CreateNewEmail
     * @summary Register new email for journey started by wallet
     * @request POST:/emails
     * @secure
     */
    createNewEmail: (data: EmailNewRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email
     * @name ConfirmNewEmail
     * @summary Confirm new email with otp
     * @request POST:/emails/create_confirm
     * @secure
     */
    confirmNewEmail: (data: EmailConfirmRequest, params: RequestParams = {}) =>
      this.request<ConfirmEmailNewResponse, ConfirmEmailErr>({
        path: `/emails/create_confirm`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags email
     * @name RegisterEmailChange
     * @summary Register email change
     * @request POST:/emails/change
     * @secure
     */
    registerEmailChange: (data: EmailChangeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email
     * @name ConfirmEmailChange
     * @summary Confirm email change token
     * @request POST:/emails/change/{confirmation_token}
     */
    confirmEmailChange: (confirmationToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/change/${confirmationToken}`,
        method: "POST",
        ...params,
      }),
  };
  oauth = {
    /**
     * No description
     *
     * @tags oauth
     * @name TrackRejection
     * @summary Track a rejection
     * @request POST:/oauth/track-rejection
     */
    trackRejection: (data: TrackRejectionRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/oauth/track-rejection`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags oauth
     * @name GetToken
     * @summary Get a token
     * @request POST:/oauth/token
     */
    getToken: (data: OauthTokenRequest, params: RequestParams = {}) =>
      this.request<OauthTokenResponse, any>({
        path: `/oauth/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags oauth
     * @name Authorize
     * @summary Build an authorization
     * @request GET:/oauth/authorize
     */
    authorize: (
      query: {
        client_id: string;
        redirect_uri: string;
        response_type: string;
        scope: string;
        state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OauthAuthorizationResponse, any>({
        path: `/oauth/authorize`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags oauth
     * @name GrantAuthorization
     * @summary Create an authorization
     * @request POST:/oauth/authorize
     */
    grantAuthorization: (data: OauthAuthorizationRequest, params: RequestParams = {}) =>
      this.request<OauthAuthorizationResponse, any>({
        path: `/oauth/authorize`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags oauth
     * @name GetAuthorizedApplications
     * @request GET:/oauth/authorized-applications
     */
    getAuthorizedApplications: (params: RequestParams = {}) =>
      this.request<AuthorizedApplication[], any>({
        path: `/oauth/authorized-applications`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  session = {
    /**
     * @description Sign in to your account
     *
     * @tags session
     * @name ConfirmToken
     * @summary Sign in
     * @request POST:/session
     */
    confirmToken: (data: ConfirmTokenRequest, params: RequestParams = {}) =>
      this.request<ConfirmTokenResponse, void>({
        path: `/session`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sign out of your account
     *
     * @tags session
     * @name Logout
     * @summary Sign out
     * @request DELETE:/session
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/session`,
        method: "DELETE",
        ...params,
      }),
  };
  account = {
    /**
     * @description Sign in to your account.
     *
     * @tags account
     * @name SignIn
     * @summary Sign in
     * @request POST:/account/sign_in
     */
    signIn: (data: SignInRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/account/sign_in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get account data.
     *
     * @tags account
     * @name Me
     * @summary Get account
     * @request GET:/account/me
     */
    me: (params: RequestParams = {}) =>
      this.request<Account, void>({
        path: `/account/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Sign in with your wallet.
     *
     * @tags account
     * @name WalletSignIn
     * @summary Wallet sign in
     * @request POST:/account/wallet_sign_in
     */
    walletSignIn: (data: WalletSignInRequest, params: RequestParams = {}) =>
      this.request<WalletSignInResponse, any>({
        path: `/account/wallet_sign_in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sign in with your wallet.
     *
     * @tags account
     * @name WalletSignInConfirm
     * @request POST:/account/wallet_sign_in_confirm
     */
    walletSignInConfirm: (data: WalletSignInConfirmRequest, params: RequestParams = {}) =>
      this.request<WalletSignInConfirmResponse, any>({
        path: `/account/wallet_sign_in_confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  factors = {
    /**
     * No description
     *
     * @tags totp
     * @name Totp
     * @summary Two factor authentication
     * @request GET:/factors/totp
     */
    totp: (params: RequestParams = {}) =>
      this.request<Totp, any>({
        path: `/factors/totp`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Set two factor authentication.
     *
     * @tags totp
     * @name CreateTotp
     * @summary Set two factor authentication
     * @request POST:/factors/totp
     */
    createTotp: (data: TotpRequest, params: RequestParams = {}) =>
      this.request<CreateTotpResponse, any>({
        path: `/factors/totp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Remove two factor authentication.
     *
     * @tags totp
     * @name RemoveTotp
     * @summary Remove two factor authentication
     * @request DELETE:/factors/totp
     */
    removeTotp: (data: TotpRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/factors/totp`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create recovery codes.
     *
     * @tags totp
     * @name CreateRecoveryCodes
     * @summary Create recovery codes
     * @request POST:/factors/recovery-codes
     */
    createRecoveryCodes: (data: CreateRecoveryCodesRequest, params: RequestParams = {}) =>
      this.request<CreateRecoveryCodesResponse, any>({
        path: `/factors/recovery-codes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  clientPolicies = {
    /**
     * No description
     *
     * @tags clientPolicies
     * @name GetClientPolicyById
     * @summary Get client policy by id
     * @request GET:/client-policies/{id}
     */
    getClientPolicyById: (id: string, params: RequestParams = {}) =>
      this.request<ClientPolicy, any>({
        path: `/client-policies/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  clientApplication = {
    /**
     * No description
     *
     * @tags applications
     * @name GetClientApplication
     * @summary Get client application by client id
     * @request GET:/client-application
     */
    getClientApplication: (
      query: {
        /**
         * ID of the client application
         * @format uuid
         */
        client_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientApplication, any>({
        path: `/client-application`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
