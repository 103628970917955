import "styles/application.scss";

import React, { useEffect } from "react";
import { Outlet } from "react-router";
import * as Sentry from "@sentry/browser";

import config from "lib/config";

import { setCustomStyleProperties } from "lib/browser";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import LoadingSpinner from "components/common/LoadingSpinner";

import { useAppDispatch, useAppSelector } from "lib/hooks";
import { selectCurrentUser, fetchCurrentUser, selectCurrentUserFirstLoad } from "slices/currentUser";
import { isAxiosError } from "axios";

export default function App() {
  const dispatch = useAppDispatch();

  const firstLoad = useAppSelector(selectCurrentUserFirstLoad);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!firstLoad && !currentUser) {
      void dispatch(fetchCurrentUser());
    }

    setCustomStyleProperties();

    Sentry.init({
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENVIRONMENT,
      // This variable cames from webpack.DefinePlugin
      release: process.env.SENTRY_RELEASE ?? "",
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      beforeSend: (event, hint) => {
        // Don't send Axios 4xx errors to Sentry
        const error = hint.originalException;

        if (isAxiosError(error)) {
          if (error?.response?.status && error.response.status >= 400 && error.response.status < 500) {
            return null;
          }
        }

        return event;
      },
    });
  }, []);

  return (
    <div className="fractal-id-developer-dashboard">
      <div className="sidebar__wrapper">
        {currentUser && (
          <>
            <Header />
            <Sidebar />
          </>
        )}
      </div>

      <div className="content">
        {!firstLoad && <LoadingSpinner />}
        {firstLoad && (
          <div role="group" style={{ outline: "none" }} tabIndex={-1}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
}
