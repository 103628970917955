import React, { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { useParams } from "react-router";

import { selectApplication } from "slices/applications";
import config from "lib/config";
import { shouldRenderCheckpoint } from "lib/review_checkpoints";
import { fetchOctopusVerificationCase } from "lib/api";
import { useAppSelector } from "lib/hooks";
import { merge, OctopusVerificationCase, VerificationCase } from "lib/verification_case";

import LoadingSpinner from "components/common/LoadingSpinner";
import Error from "components/common/Error";

import AgeOfMajority from "components/VerificationCase/checkpoints/AgeOfMajority";
import ComplianceReports from "components/VerificationCase/checkpoints/ComplianceReports";
import Institution from "components/VerificationCase/checkpoints/Institution";
import ProofOfIdentity from "components/VerificationCase/checkpoints/ProofOfIdentity";
import ProofOfResidency from "components/VerificationCase/checkpoints/ProofOfResidency";
import SourceOfWealth from "components/VerificationCase/checkpoints/SourceOfWealth";
import Uniqueness from "components/VerificationCase/checkpoints/Uniqueness";
import Wallets from "components/VerificationCase/checkpoints/Wallets";

import Overview from "components/VerificationCase/Overview";
import Reasons from "components/VerificationCase/Reasons";
import { megalodonApiWithApplication } from "lib/apiv2";
import { VerificationCaseV1 } from "api/megalodon";

export default function ApplicationVerificationCase() {
  const { appId, verificationCaseId } = useParams<{ appId: string; verificationCaseId: string }>();

  const application = useAppSelector((state) => selectApplication(state, appId));

  const [verificationCase, setVerificationCase] = useState<VerificationCase | null>(null);
  const [error, setError] = useState<Error | string | null>(null);

  useEffect(() => {
    async function fetchVerificationCase() {
      if (!application) {
        setError(
          t({
            id: "errors.invalid_application",
            message: "Invalid application",
          }),
        );
        return;
      }

      let megalodonVC: VerificationCaseV1, octopusVC: OctopusVerificationCase;

      try {
        megalodonVC = await megalodonApiWithApplication(application)
          .verificationCases.getVerificationCase(verificationCaseId!)
          .then((response) => response.data);
      } catch (e: unknown) {
        setError(e as Error);
        return;
      }

      try {
        octopusVC = await fetchOctopusVerificationCase(application, megalodonVC.uid, megalodonVC.level);
      } catch (e) {
        octopusVC = {
          review_reasons: [],
          compliance_reports: [],
        };
      }

      if (!error && !!megalodonVC && !!octopusVC) {
        setVerificationCase(merge(megalodonVC, octopusVC));
      }
    }

    void fetchVerificationCase();
  }, [application, verificationCaseId]);

  if (error) return <Error error={error} />;

  if (verificationCase === null) {
    return <LoadingSpinner />;
  } else {
    const splitLevel = verificationCase.level.split("+");

    return (
      <div className="page">
        <div className="page__header">
          <h2>{verificationCase.full_name}</h2>
        </div>

        <div className="user-details">
          <div className="user-details__info">
            <Overview verificationCase={verificationCase} />

            <Reasons verificationCase={verificationCase} />

            {shouldRenderCheckpoint(splitLevel, "wallet") && <Wallets verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "age_of_majority") && <AgeOfMajority verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "uniqueness") && <Uniqueness verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "proof_of_identity") && <ProofOfIdentity verificationCase={verificationCase} />}

            {verificationCase.primary_role !== "institution" && shouldRenderCheckpoint(splitLevel, "proof_of_residency") && (
              <ProofOfResidency verificationCase={verificationCase} />
            )}

            {verificationCase.primary_role === "institution" && <Institution verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "source_of_wealth") && <SourceOfWealth verificationCase={verificationCase} />}

            {config.ENABLED_COMPLIANCE_REPORTS === "1" && <ComplianceReports verificationCase={verificationCase} />}
          </div>
        </div>
      </div>
    );
  }
}
