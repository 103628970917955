import axios, { AxiosInstance, AxiosResponse } from "axios";

import config from "lib/config";
import session from "lib/session";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HTTPResponse<T = any> = Promise<AxiosResponse<T>>;
export const HTTPResponse = Promise;

const headers = {
  "Content-Type": "application/json",
};

export const megalodon = axios.create({
  baseURL: config.MEGALODON_BASE_URL,
  headers,
});

export const sprat = axios.create({
  baseURL: config.SPRAT_BASE_URL,
  headers,
});

export const octopus = axios.create({
  baseURL: config.OCTOPUS_BASE_URL,
  headers,
});

export const barracuda = axios.create({
  baseURL: config.BARRACUDA_BASE_URL,
  headers,
});

const setAuthorizationHeader = (client: AxiosInstance): number =>
  client.interceptors.request.use((config) => {
    const token = session.getToken();

    if (token && config.headers && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });

[megalodon, barracuda].forEach(setAuthorizationHeader);

export default {
  megalodon,
  sprat,
  octopus,
  barracuda,
};
