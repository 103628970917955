import React from "react";

import { requiredAcceptPolicy } from "lib/policyAcceptance";

import PolicyAcceptance from "components/PolicyAcceptance";
import { Outlet } from "react-router";
import { useAppSelector } from "lib/hooks";
import { selectCurrentUser } from "slices/currentUser";

export default function RequiresPolicyAcceptance() {
  const currentUser = useAppSelector(selectCurrentUser);

  if (!currentUser || requiredAcceptPolicy(currentUser)) {
    return <PolicyAcceptance />;
  }

  return <Outlet />;
}
