import React, { useState, useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import axios from "axios";

import { goBackUrl } from "lib/browser";
import {
  createApplicationUser,
  fetchApplicationUsers,
  deleteApplicationUser,
  selectApplicationUsers,
  selectApplicationUsersLoading,
} from "slices/applicationUsers";
import ApplicationUserForm from "components/forms/ApplicationUser";
import Error from "components/common/Error";
import { useAppSelector } from "lib/hooks";
import { ApplicationUserRequest, type ApplicationUser } from "api/catfish";
import LoadingSpinner from "components/common/LoadingSpinner";
import { selectCurrentUserEmail } from "slices/currentUser";

const mapDispatchToProps = {
  createApplicationUser,
  fetchApplicationUsers,
  deleteApplicationUser,
};

const connector = connect(undefined, mapDispatchToProps);

type ApplicationClientsPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationClients({
  createApplicationUser,
  fetchApplicationUsers,
  deleteApplicationUser,
}: ApplicationClientsPropsFromRedux) {
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => selectApplicationUsersLoading(state, appId));
  const users = useAppSelector((state) => selectApplicationUsers(state, appId));
  const myEmail = useAppSelector(selectCurrentUserEmail);

  useEffect(() => {
    if (!users && !loading && appId) {
      void fetchApplicationUsers(appId);
    }
  }, []);

  const [error, setError] = useState<Error | string | null>(null);

  if (!appId) return <LoadingSpinner />;

  const responseError = t({
    id: "errors.connectivity",
    message: "Connection error, please try again.",
  });
  const forbiddenError = t({
    id: "errors.forbidden",
    message:
      "Adding the team member is prohibited. Possible reasons: you have no rights to add team members, this member is already added.",
  });

  const dataTableHeaders = [
    [
      t({
        id: "application_users.user_list.email",
        message: "Email",
      }),
      "60%",
    ],
    [
      t({
        id: "application_users.user_list.role",
        message: "Role",
      }),
      "20%",
    ],
    [
      t({
        id: "application_users.user_list.remove",
        message: "Remove",
      }),
      "20%",
    ],
  ];

  const renderRow = (user: ApplicationUser) => {
    return (
      <>
        <td>{user.email}</td>
        <td>{user.role}</td>
        <td>
          {user.email !== myEmail && (
            <button type="button" className="btn btn-grey" onClick={() => void onDelete(user.uid)}>
              <Trans id="application_users.remove_button">Remove</Trans>
            </button>
          )}
        </td>
      </>
    );
  };

  const onSubmit = (values: ApplicationUserRequest) => {
    createApplicationUser({ appId, ...values }).catch((e) => {
      let forbiddenType = false;
      if (axios.isAxiosError(e) && e?.response?.status && e.response.status < 500) forbiddenType = true;
      if (forbiddenType) {
        setError(forbiddenError);
      } else {
        setError(responseError);
      }
    });
  };

  const onDelete = (userId: string) => deleteApplicationUser({ appId, userId });

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application_users.title">Team members</Trans>
        </h2>
      </div>
      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>
        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application_users.new_team_member">Add a new team member</Trans>
          </div>
          <div className="application__card--body">
            <ApplicationUserForm onSubmit={onSubmit} />
            {error !== null && <Error error={error} />}
          </div>
        </div>
        {!loading && (
          <div className="application__card">
            <div className="application__card--body">
              <div className="datatable">
                <table>
                  <thead>
                    <tr>
                      {dataTableHeaders.map(([label, width], i) => (
                        <th key={i} style={{ width }}>
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {<tbody>{users && users.map((user, i) => <tr key={i}>{renderRow(user)}</tr>)}</tbody>}
                </table>
              </div>
            </div>
          </div>
        )}
        {loading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default connector(ApplicationClients);
