import React from "react";
import { WrappedFieldProps } from "redux-form";

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const Input = ({ input, meta: { touched, error, warning }, type = "text", ...props }: WrappedFieldProps & InputProps) => (
  <div className="form-control">
    <input {...input} type={type} {...props} />
    {touched && error && <span className="text-danger">{error}</span>}
    {touched && warning && <span className="text-danger">{warning}</span>}
  </div>
);

export default Input;
